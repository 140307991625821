.collapseContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .panel {
        border-radius: 0.625rem;
        background: #fff;
        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);

        .topPanel {
            cursor: pointer;
            padding: 1rem;
            height: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .headerPanel {
                color: #463649;
                font-family: 'Poppins SemiBold';
                font-size: 1rem;
                line-height: 130%;
            }

            .iconPanel {
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
            }
        }

        .bottomPanel {
            display: none;
            visibility: hidden;
            padding: 1rem;
            padding-top: 0rem !important;
            .descriptionPanel {
                margin-bottom: 1rem;
                color: #494c52;
                font-family: 'Poppins Regular';
                font-size: 0.875rem;
                line-height: 150%;
            }
        }

        .showBottomPanel {
            display: block;
            visibility: visible;
        }
    }
    .text-and-diamond {
        display: flex;
        align-items: center;
        gap: 10px;
        .diamondSize{
            width: 21px;
            height: 21px;
        }
    }
}
