.ant-tooltip{
    .ant-tooltip-inner{
        font-family: "Poppins Regular";
        font-size: 0.875rem;
        border-radius: 1.25rem !important;
        ul{
            margin-left: 10px;
            padding-left: 10px;
        }
    }
}