	.enterPassCodeBox {
		.title{
			font-size: 2.625rem;
			font-family: "Poppins SemiBold";
			font-weight: 600;
			color: #262626;
			padding-bottom: 0 !important;
			margin-bottom: 1% !important;
		}
	
		.subtitle{
			color: #7C88A0;
			font-size: 1.375rem;
			font-family: "Poppins Medium";
			font-weight: 500;
			margin-bottom: 2%;
		}

		@media (max-width: 450px) {
			.title{
				font-size: 1.375rem;
				line-height: 1.8rem;
			}
		
			.subtitle{
				font-size: 1rem;
				margin: 3% 0 6% 0;
			}
		}
        
	}

   
