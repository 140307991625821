.noteContainer{
    padding: 15px;
    width: 388px;
    height: max-content;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(217, 171, 254, 0.15);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    text-align: left;
    .topVector{
        position: absolute;
        top: 10px;
        z-index: 1;
        width: 100%;
        height: auto;
    }
    .name{
        font-family: 'Poppins Medium';
        font-size: 16px;
        line-height: 130%;
        color: #262626;
        margin-bottom: 10px;
    }

    .description{
        font-family: "Poppins Regular";
        font-size: 14px;
        line-height: 150%;
        color: #717782;
        margin-bottom: 10px;
    }

    
    
    .details{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .left{
            font-family: 'Poppins SemiBold';
            font-size: 10px;
            line-height: 17px;
            color: #717782;
        }

        .right{
            .tag {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 70px;
                height: 20px;
                background: #F9EFFF;
                border: 1px solid black;
                border-radius: 16px;
                font-family: 'Poppins Bold';
                font-size: 12px;
                text-align: center;
                letter-spacing: 0.065em;
                text-transform: uppercase;
                color: #463649;
                cursor: pointer !important;


                .spinnerForDelete{
                    .ant-spin-dot-item{
                        background-color: #463649;
                    }
                }
            }
        }
    }

    .bottomVector{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: auto;
        bottom: 0;
    }

    @media (max-width: 450px){
        width: 100%;
    }
}