.legacyButtonContainer{
    width: max-content;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border-radius: 60px;
    font-family: 'Poppins Medium';
    font-size: 1.375rem;
}

.base1Btn{
    border: 3px solid var(--primary);
    background-color: var(--primary);
    color: #FFF;

    &:hover {
        background-color: var(--primary);
        border: 3px solid var(--primary);
        color: #FFF;
    }
    &:visited {
        background-color: var(--primary);
        border: 3px solid var(--primary);
        color: #FFF;
    }
    &:focus {
        background-color: var(--primary);
        border: 3px solid var(--primary);
        color: #FFF;
    }
}

.base2Btn{
    border: 3px solid var(--secondary);
    background-color: var(--secondary);
    color: #FFF;

    &:hover {
        background-color: var(--secondary);
        border: 3px solid var(--secondary);
        color: #FFF;
    }
    &:visited {
        background-color: var(--secondary);
        border: 3px solid var(--secondary);
        color: #FFF;
    }
    &:focus {
        background-color: var(--secondary);
        border: 3px solid var(--secondary);
        color: #FFF;
    }
}

.primaryBtn{
    color: #FFF;
    border: 3px solid var(--button-border-color);
    background-color: var(--button-background-color);

    &:hover {
        background-color: #000;
        border: 3px solid #000;
        color: #FFF;
    }
    &:visited {
        background-color: #000;
        border: 3px solid #000;
        color: #FFF;
    }

    &:focus {
        background-color: #000;
        border: 3px solid #000;
        color: #FFF;
    }
}

.secondaryBtn{
    // color: #D9ABFE;
    color: var(--button-background-color);
    // border: 3px solid rgba(217, 171, 254, 0.25);
    border: 3px solid var(--button-border-color);
    background-color: #FFF;

    &:hover {
        background-color: var(--button-border-color);
        border: 3px solid var(--button-border-color);
        color: #FFF;
    }
    &:visited {
        background-color: var(--button-border-color);
        border: 3px solid var(--button-border-color);
        color: #FFF;
    }
    &:focus {
        background-color: var(--button-border-color);
        border: 3px solid var(--button-border-color);
        color: #FFF;
    }
    
}

.disabledBtn{
    cursor: not-allowed;
    color: #AEAEAE;
    border: 3px solid #EDEDED;
    background-color: #EDEDED;

    &:hover {
        background-color: #EDEDED;
        border: 3px solid #EDEDED;
        color: #AEAEAE;
    }
    &:visited {
        background-color: #EDEDED;
        border: 3px solid #EDEDED;
        color: #AEAEAE;
    }
    &:focus {
        background-color: #EDEDED;
        border: 3px solid #EDEDED;
        color: #AEAEAE;
    }
}

@media (max-width: 450px) { 
    .legacyButtonContainer{
        height: 34px;
        font-size: 0.8125rem;
        padding: 7.5px 20px;
    }
}