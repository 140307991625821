.donationDropdownContainer{
    padding: 15px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #463649 !important;
    background: rgba(250, 243, 247, 0.54);
    color: #463649 !important;
    font-family: "Poppins Regular";
    font-size: 1.125rem;
    line-height: 1.875rem;
    letter-spacing: -0.03125rem;
    outline: none !important;
    box-shadow: none !important;
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.customFundInput {
    height: 60px;
    width: 100%;
    border-radius: 10px;
    background: rgba(250, 243, 247, 0.54);
    border: 1px solid #463649;
    font-family: "Poppins Regular";
    font-size: 1.125rem;
    line-height: 1.875rem;
    letter-spacing: -0.03125rem;
    color: #463649;
    padding: 15px;

    &:focus {
        box-shadow: none !important;
        border: 1px solid #463649;
    }

    &:hover {
        box-shadow: none !important;
        border: 1px solid #463649;
    }

    &:active {
        box-shadow: none !important;
        border: 1px solid #463649;
    }

    &::placeholder {
        color: #9E9E9E;
        font-family: 'Poppins Regular';
        font-size: 1.125rem ;
        line-height: 1.875rem;
        letter-spacing: -0.03125rem;
    }

    .ant-input {
        box-shadow: none !important;
        outline: none !important;
        &::placeholder {
            color: #9E9E9E;
            font-family: 'Poppins Regular';
            font-size: 1.125rem;
            line-height: 1.875rem;
            letter-spacing: -0.03125rem;
        }
    }
}

.ant-dropdown-menu {
    border-radius: 0.625rem;
    border: 1px solid #463649;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
    padding: .7rem 3px !important;

    .ant-dropdown-menu-title-content{
        color: #463649 !important;
        font-family: "Poppins Regular" !important;
        font-size: 1.125rem !important;
        line-height: 1.875rem !important;
        letter-spacing: -0.03125rem !important;
    }
    
    @media (max-width: 450px) { 
    }
    
}

.ant-dropdown-menu-item{
    background-color: transparent !important;
}