.upgradeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    .heading {
        color: #463649;
        font-family: "Poppins Bold";
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.045rem;
        margin-bottom: 20px;
    }
    .paymentBox{
        width: 100%;
    }
    .learnMoreBtn{
        margin-top: 20px;
    }
}