.gemscard-parent {
    background-color: white;
    box-shadow: 0px 4px 6px rgba(115, 115, 115, 0.05);
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .gemscard-info-icon {
        width: 25px;
        height: 25px;
    }
    
    .gemscard-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding-right: 5px;
        .gemscard-hide {
            color: darkred;
            text-decoration: underline;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
        }
       .getStarted{
        font-family: "Poppins Bold";
        line-height: 1.6rem;
        color: #463649;
        margin-bottom: 10px;
    }
    
    
    }
    .gemscard-rotation {
        display: flex;
        gap: 18px;
    }
    .gemscard-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
        margin-top: 10px;
        .gemscard-germs {
            display: flex;
            align-items: center;
            gap: 2px;
            
        }
        .gemscard-germs-earned {
            color: #463649;
            font-size: 1rem;
            font-weight: bold;
        }
    }
    .premiumFeatureButton{
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width:100%;
        background-color: red;
    }
        
        
        
}
