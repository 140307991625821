.navigationHeaderContainer{
    
    
    .dashboard{
        color: #463649;
        font-family: "Poppins Bold";
        font-size: 2.8125rem;
        line-height: 3.09375rem;
        letter-spacing: -0.08438rem;
    }

    .other{
        color: #463649;
        font-family: "Poppins SemiBold";
        font-size: 1.5rem;
        line-height: 1.9375rem;
        letter-spacing: -0.045rem;
    }

    .navigationForDesktop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.5rem;

        .rightSide{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }
    }
    
    .navigationForMobile{
        padding-top: 2rem !important;
        margin-bottom: 1.5rem;
        width: 100%;
        .navigationForMbTop{
            padding: 0 1.37rem !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo{
                width: 4.71744rem;
                height: 2.43063rem;
            }

            .upgradePlanBtn{
                width: 2.625rem;
                height: 2.625rem;
                background-color: #A3D080;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
        }

        .navigationForMbBottom{
            margin-top: 1.5rem;
        }
    }

    @media(max-width: 450px){
        .profileSetUp{
            color: #463649;
            font-family: "Poppins SemiBold";
            text-align: center;
            font-size: 2rem;
            line-height: 2rem;
            letter-spacing: -0.06rem;
        }
    }
}