.dividerContainer {
    width: 100%;
    margin: 15px 0;
    border-radius: 3px;
    height: 1px;
    background-color: rgba(70, 54, 73, 0.5);

    @media (max-width: 450px) {
        margin: 16px 0;
        border-radius: 0px;
        height: 1px;
    }
}

.verticalDivider {
    width: 3px;
    height: 100%;
    margin: 0 15px;
    background-color: rgba(70, 54, 73, 0.5);

    @media (max-width: 450px) {
        width: 1px;
    }
}

.hideOnMobile {
    display: none;
}

.hideOnDesktop {
    display: none;
}
