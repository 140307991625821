.premiumFeatureModalContainer {

    .title{
        font-size: 1.375rem !important;
        margin-bottom: 10px !important;
    }

    .slider{
        margin-bottom: 20px;
        @media (max-width: 450px){
            margin-bottom: 10px;
        }
    }
    .ant-carousel {
        text-align: center;
    }

    .slide {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%; 
        height: 350px;

        .crouselHeading{
            margin-top: 20px;
        }
        .image {
            width: 100%; 
            border: 1px solid #e1e1e1;
            height: 55%;

        }
        .text {
            text-align: center;
            width: 100%;
            white-space: normal;
            word-wrap: break-word;
        }
    }
.list{
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 20px;
    margin-left: -30px;
}

    .custom-dots {
        position: relative;
        display: flex !important;
        justify-content: center;
        align-items: center;

        .slick-active{
            background-color: blue !important;
        }
        @media (max-width: 450px){
            margin-top: -40px;
        }
    }
    
    .ant-carousel .slick-dots li {
        width: 8px;
        height: 8px;
        background-color: #e0e0e0 !important; 
        border-radius: 50%;
    }

    .ant-carousel .slick-dots li button{
        opacity: 0% !important;
    }
    .ant-carousel .slick-dots li.slick-active button{
        background-color: black !important; 
    }

    .ant-carousel .slick-dots .slick-active {
        width: 30px; 
        height: 6px; 
        border-radius: 3px; 
        background-color: black !important; 
        opacity: 1 !important; 
        filter: none !important; 
    }

   .premiumFeatureModalButton {
        display: flex;
        align-items: center;
        justify-content: flex-end;

    }
}

.premiumFeatureModal {
    .ant-modal-content{
        width: 45%;
        padding: 30px;
        height: 130% !important;
        @media (max-width: 450px){
            width: 100%;
        }
    }
}
