.contributionModalContainer{
    display: flex;
    justify-content: center;
    .leftSide{
        position: relative;
        .headline{
            font-family: 'Poppins Bold';
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.03em;
            color: #463649;
            margin-bottom: 16px;
        }
    
        // .imageBox{
        //     // width: 387.5px;
        //     // height: 12.625rem;
        //     overflow: hidden;
        //     border-radius: 10px;
        //     object-fit: contain;

        //     .image{
        //         width: 100%;
        //         height: 100%;
        //     object-fit: cover;
        //         border: 1px solid red
        //     }
        // }
        .imageBox{
            width: 190.5px;
            height: 190.5px;
            object-fit: contain;
            border-radius: 10px;
            overflow: hidden;

            .image {
                width: 100%;
                height: auto;
                border-radius: 10px;
            }

            
        }

        @media (max-width: 450px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        
    }

    .rightSide{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 40px;
        .customDonation{
            padding: 6px 0px;
            width: 222px;
            height: 60px;
            background: #F6F6F6;
            border-radius: 10px;
    
            .donation{
                font-family: 'Poppins Bold';
                font-size: 32px;
                line-height: 32px;
                letter-spacing: -0.03em;
                color: #463649;
            }
    
            .btn{
                cursor: pointer;
            }
        }
    
        .preSelectDonation{
            gap: 10px;
            width: 388px;
            .btn{
                padding: 12px 9px;
                min-width: 82px;
                max-width: max-content;
                height: 51px;
                background: #F6F6F6;
                border-radius: 10px;
                font-family: 'Poppins Bold';
                font-weight: 700;
                font-size: 16px;
                line-height: 27px;
                color: #463649;
                cursor: pointer;
            }

            .isPreSelectedBtnSelected{
                color:#F6F6F6;
                background: #463649;
            }
        }
    
        .contributionBtn{
            width: 362px;
            text-transform: capitalize;
        }
    }
   
    .headlineStep3{
        font-family: 'Poppins Bold';
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.03em;
        color: #463649;
        margin-bottom: 16px;
    }

    @media (max-width: 450px) {
        padding-top: 25px;
        .leftSide{
            .image{
                width: 100%;
            }
        }

        .rightColumn{
            width: 100%;

            .rightSide{
                margin-top: 24px;
                .preSelectDonation{
                    width: 100%;

                    display: flex;
                    justify-content: center;
                    gap: 20px;
    
                    .btn{
                        width: max-content;
                    }
                }
            }
        }
        
    }
    
}

.viewContributionModal{
    @media (max-width: 450px) {
        .ant-modal-content{
            overflow: unset;
        }
    }
}