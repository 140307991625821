.viewVilliePhotosModal{
    height: max-content !important;
    overflow-y: visible;
}

.viewVilliePhotosModalContainer{
    display: flex;
    justify-content: center;

    .viewVilliePhotosModalSection{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    @media (max-width: 450px) {
        justify-content: left;

        .viewVilliePhotosModalSection{
            width: 100%;

            .heading{
                text-align: left;
            }
        }
    }
}