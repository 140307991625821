.spinnerBox{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    .image{
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 450px) {
}