.previewBoxContainer{
    .topPreview{
        position: relative;
        .previewBtn{
            padding: 8px 18px;
            color: #FFF;
            font-family: "Poppins Regular";
            font-size: .875rem;
            line-height: 0.9375rem;
            letter-spacing: 0.04875rem;
            border-radius: 1.25rem;
            border: 1px solid #FFF;
            background: #463649;
            box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
        width: 100%;
        border-radius: 0.625rem;
        background: #FCE4F9;
        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
    .bottomPreview{
        display: none;
        visibility: hidden;
    }


    @media(max-width: 450px) {
        .topPreview{
            height: 16rem;
            margin-bottom: .88rem;

        }

        .bottomPreview{
            display: block;
            visibility: visible;
        }
    }
}