.updatesAndMilestoneSectionContainer{
    .lastRow{
        margin-top: 24px;
        display: flex;
        justify-content: end;
    }
    .spanText{
        color: var(--preview-span-color);
    }

    @media (max-width: 450px) {
    }
}