.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    max-width: 300px;
    font-family: Arial, sans-serif;
    width: 23%;
    height: 220px;
    display: flex;
    flex-direction: column; /* Stack headerRow and numberSection vertically */
    justify-content: space-between; /* Ensure spacing between sections */

    .headerRow {
        display: flex;
        justify-content: space-between; /* Align title and icon */
        align-items: center;

        .title {
            font-family: 'Poppins Regular';
            font-size: 0.875rem;
            margin: 0;
        }

        .number {
            font-size: 0.875rem;
            font-family: 'Poppins Bold';
            margin: 0;
        }

        .card-icon {
            font-size: 1rem;
            color: #888;
        }
    }

    .numberSection {
        display: flex;
        flex-direction: column; /* Stack content and button vertically */
        flex-grow: 1; /* Allow this section to take up remaining space */
        justify-content: flex-start; /* Align number and details at the top */

        .number {
            font-size: 2rem;
            font-family: 'Poppins Bold';
            margin: 0;
            color: #000;
        }

        .growth {
            font-family: 'Poppins Regular';
            font-size: 0.9rem;
            color: #888;
            margin: 0;
            margin-top: -10px;
        }
    }

    .actionButton {
        margin-top: auto; /* Push the button to the bottom of the card */
        align-self: center; /* Center the button horizontally */
        width: 100%; /* Ensure button spans full width */
        font-family: 'Poppins Regular';
        background-color: #f4f4f4;
        color: #000;
        text-align: center;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        border: none;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #e4e4e4;
        }

        &:focus {
            outline: none;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        }
    }

    @media(max-width: 450px) {
        width: 47%;
        height: 230px;

        .numberSection{
            .growth{
                margin-bottom: 10px;
            }
        }
    }
}
