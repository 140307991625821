.actionContainer{
    width: 94%;
    // padding: 0.625rem 1.5625rem 0.5625rem 1.5625rem;
    padding: 0.625rem 0 0.5625rem 0;
    border-radius: 1.25rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
    
    margin-left: 3%;

    display: none;
    visibility: hidden;

    &.sticky {
        border-radius: 0px 0px 20px 20px;
        width: 100%;

        z-index: 100;
        position: fixed;
        margin-left: 0;
        bottom: -1px;
    }

    

    .bottomRowOfActions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 1.5625rem;
        margin-right: 1.5625rem;
        .action{
            cursor: pointer;
            display: flex;
            flex-direction: column;
            color: #463649;
            text-align: center;
            font-family: "Poppins Medium";
            font-size: 0.625rem;
            line-height: 1.0625rem;
            text-transform: uppercase;
            
            .icon{
                width: 1.625rem;
                height: 1.625rem;
            }
        }
    }
    
    .addNotesBtn {
        width: max-content !important;
        border-radius: 0;
        z-index: 1 !important;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        position: fixed;
        bottom: 15%;
        right: 0px;
    }

    @media(max-width: 450px){
        display: flex;
        flex-direction: column;
        visibility: visible;

        .topRowOfActions{
            z-index: 2;
            width: 100%;
            .moreSection{
                .top{
                    width: 100%;
                    margin: 20px 11px 16px 11px;
                    display: flex;
                    justify-content: space-between;

                    .heading{
                        font-family: 'Poppins Bold';
                        font-size: 24px;
                        line-height: 32px;
                        letter-spacing: -0.03em;
                        color: #463649;
                    }
                    .closeBtn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50px;
                        width: 25px;
                        height: 25px;
                        background: rgba(38, 38, 38, 0.15);
                    }
                }
                .middle{
                    // .topVector{
                    //     position: absolute;
                    //     top: 10px;
                    //     z-index: 1;
                    //     width: 100%;
                    //     height: auto;
                    // }

                    .f{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;

                        .ac{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 48%;
                            height: 90px;
                            background: #FFFFFF;
                            border-radius: 10px;
                            color: #463649;
                            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10);
                            font-family: "Poppins Bold";
                            font-size: 0.9375rem;
                            z-index: 5;
                        }
                    }
                    

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    padding: 15px;
                    background: #F9EFFF;
                    box-shadow: 0px 0px 25px rgba(217, 171, 254, 0.15);
                    border-radius: 10px;

                    // .bottomVector{
                    //     position: absolute;
                    //     z-index: 1;
                    //     width: 100%;
                    //     height: auto;
                    //     bottom: 0;
                    // }

                    .contributionButton{
                        margin-top: 14px;
                        width: 80% !important;
                    }
                }
                .bottom{
                    display: flex;
                        justify-content: space-around;
                        justify-content: center;
                        align-items: center;
                    .b{
                        width: 78%;
                        display: flex;
                        justify-content: space-around;
                        padding-top: 32px;
                        padding-bottom: 25px;

                        div{
                            font-family: 'Poppins Regular';
                            font-size: 10px;
                            line-height: 17px;
                            color: #463649;
                            cursor: pointer;
                        }
                    }
                    
                }
            }
        }

        .addNotesBtn {
            padding: 0 10px;
        }
    }
}