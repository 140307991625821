.viewEventsModal{
    height: max-content !important;
    overflow-y: visible;
}
.viewEventsModalContainer{
    display: flex;
    justify-content: center;

    .viewEventsModalSection{
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;

        .heading{
            text-align: center;
        }

        .eventsArea{
            background: var(--theme-color);
            border-radius: 60px;
            padding: 30px;
            // display: flex;
            gap: 2%;
        }
        
    }

    @media (max-width: 450px) {
        justify-content: left;

        .viewEventsModalSection{
            width: 100%;

            .heading{
                text-align: left;
            }

            .eventsArea{
                gap: 3%;
                border-radius: 30px;
            }
        }
    }
}