.introSectionContainer {
    .navbar {
        padding: 19px 32px;
        gap: 10px;
        width: 100%;
        height: 81px;
        background: #FFFFFF;
        border-radius: 20px;

        .logo {
            width: 94.99px;
            height: 48.95px;
        }

        .rightSide {
            display: flex;
            gap: 30px;
            align-items: center;

            .tab {
                padding: 0;
                margin: 0;
                font-family: 'Poppins Regular';
                font-weight: 400;
                font-size: 17px;
                color: #463649;
                cursor: pointer;
            }
        }
    }

    .hero {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 50px;

        .leftSide {
            width: 55%;

            margin-top: 65px;
            margin-bottom: 40px;
            background-color: #FFFFFF;
            border-radius: 20px;
            padding: 40px;


            .headline {
                font-family: 'Poppins Bold';
                font-size: 65px;
                line-height: 68px;
                letter-spacing: -0.03em;
                color: var(--text-color);
                margin-bottom: 28px;
            }
            .spanText{
                color: var(--preview-span-color);
            }        

            .personalInfo {
                display: none;
                visibility: hidden;
            }

            .discription {
                font-family: 'Poppins Regular';
                font-size: 16px;
                line-height: 27px;
                margin-top: 28px;
                color: #717782;
                display: flex;
                flex-direction: column;
                gap: 24px;
                white-space: pre-wrap;

                span:nth-child(2) {
                    display: none;
                }

                .ant-typography-expand {
                    font-family: 'Poppins Regular';
                    font-size: 10px;
                    line-height: 17px;
                    color: #463649
                }
            }

            .journeyBtn{
                width: 200px;
            }

            .supportorsCountsButton{
                width: 200px;
                height: 43px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 5px;
                // padding: 10px 28px;
                // border-radius: 0.625rem;
                font-family: 'Poppins Bold';
                font-size: 15px;
                color: #494C52;
                // line-height: 22px;
                // border-radius: 25px;
                margin-bottom: 20px;
            }
        }

        .rightSide {
            width: 24.25rem;
            display: flex;
            justify-content: flex-end;
            position: relative;

            .parentInfoBox {
                position: absolute;
                z-index: 1;
                width: 20rem;
                height: 64px;
                left: 2rem;
                bottom: 1rem;
                background-color: #FFFFFF;
                border-radius: 10px;
            }

            .image {
                width: 24.25rem;
                height: 26.875rem;
                border-radius: 3.75rem;
                // background-size: cover;
                // background-position: center;
                object-fit: cover;
            }
            
        }
    }
}

@media (max-width: 450px) {
    .introSectionContainer {
        .navbar {
            padding: 17px 22px;

            .logo {
                width: 75.48px;
                height: 38.89px
            }

            .rightSide {
                .tab {
                    display: none;
                    visibility: hidden;
                }
            }
        }

        .hero {
            margin-top: 29px;

            .leftSide {
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 0px;

                .headline {
                    font-size: 32px;
                    line-height: 32px;
                    margin-bottom: 24px;
                }

                .personalInfo {
                    display: block;
                    visibility: visible;
                    position: relative;
                    margin-bottom: 1.5rem;
                    .image {
                        height: 26.875rem;
                        border-radius: 3.75rem;
                        width: 100%;
                    }

                    .parentInfoBox {
                        position: absolute;
                        z-index: 1 !important;
                        width: 86%;
                        left: 7%;
                        bottom: 5%;
                        background-color: rgba(254, 254, 254, 0.9);
                        border-radius: 10px;
                    }
                }

                .journeyBtn{
                    width: 94%;
                }
                
                .supportorsCountsButton{
                    width: 100% !important;
                    height: 2.6875rem;
                    font-size: 0.9375rem;
                }
                .discription {
                    font-size: 0.875rem;
                    line-height: 150%;
                    margin-top: 1.5rem;
                    gap: 0;
                    .ant-typography-expand {
                        font-size: 0.625rem;
                        line-height: 1.0625rem; 
                        margin-top: 1rem;
                    }
                }
            }

            .rightSide {
                display: none;
                visibility: hidden;
            }
        }
    }
}