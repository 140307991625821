// .photoCollageContainer{
//     display: grid;
//     // grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
//     gap: 20px;

//     .column{
//         display: flex;
//         flex-direction: column;
//         gap: 20px;
        
//         .image1{
//             width: 280px;
//             height: 303px;
//             border-radius: 10px;
//             object-fit: cover;
//         }
    
//         .image2{
//             width: 280px;
//             height: 178px;
//             border-radius: 10px;
//             object-fit: cover;
//         }
//     }

//     @media (max-width: 450px){
//         // grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
//         gap: 2.5%;
//         width: 100%;

//         .column{
//             gap: 10px;
//             .image1{
//                 width: 100%;
//                 height: 260px;
//                 border-radius: 10px;
//                 object-fit: cover;
//             }
        
//             .image2{
//                 width: 100%;
//                 height: 153px;
//                 border-radius: 10px;
//                 object-fit: cover;
//             }
//         }
//     }
// }

.photoCollageContainer{
    .image1{
        width: 280px;
        height: 303px;
        border-radius: 10px;
        object-fit: cover;
    }

    .ant-image-mask{
        width: 280px;
        height: 303px;
        border-radius: 10px;
    }

    @media(max-width:  450px){
        .image1{
            width: 100%;
            height: 16.25rem;
            border-radius: 0.625rem;
        }
    }
}
