.editContainer{
    width: 100%;
    .innerContainer{
        width: 90%;
        margin-left: 5%;
        margin-top: 1.56rem;
        padding-bottom: 8rem;
        .middle{
            display: flex;
            justify-content: space-between;
            .left{
                width: 43%;

                .btn{
                    width: 100%;
                    margin-top: 1.5rem;
                }
            }

            .right{
                width: 54%;
            }

        }
    }


    @media (max-width: 450px) {
        margin-top: -25px;
        background: var(--theme-color);

        .innerContainer{
            background: #FFF;
            width: 96%;
            margin-left: 2%;
            padding: 0 .69rem;
            padding-bottom: 1.62rem;

            .middle{
                flex-direction: column-reverse;

                .right{
                    width: 100%;
                }

                .left{
                    width: 100%;
                }
            }
        }
    }
}