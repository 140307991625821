.ant-popover {
    .ant-popover-arrow {
        visibility: hidden;
        display: none;
    }

    .ant-popover-content {
        width: 300px;
        margin-left: 5%;
        .ant-popover-inner-content {

            .popoverContainer {
                .content {

                    .subtitle {
                        font-size: 1.1875rem;
                        font-family: 'Poppins Medium';
                        margin-bottom: 20px;
                        color: #000;
                    }

                    .buttonBox{
                        display: flex;
                        gap: 10px;
                        .btns{
                            height: 34px;
                            font-size: 0.8125rem;
                            padding: 7.5px 20px;
                        }

                        .secondryBtn{
                            &:hover {
                                background-color: #000;
                                border: 3px solid #000;
                                color: #FFF;
                            }
                            &:visited {
                                background-color: #000;
                                border: 3px solid #000;
                                color: #FFF;
                            }
                            &:focus {
                                background-color: #000;
                                border: 3px solid #000;
                                color: #FFF;
                            }
                        }
                    }
                    
                }
            }


            @media (max-width: 450px){
                .popoverContainer {
                    .content {
                        .subtitle {
                            font-size: 0.8125rem;
				            margin-bottom: 10px;
                        }

                        .buttonBox{
                            margin-top: 20px;

                            .btns{
                                height: 28px  !important;
                                font-size: 0.8125rem;
                                padding: 6px 14px  !important;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}