.personalInfoWidgeContainer {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6%;

    .personalInfoTitle {
        font-family: 'Poppins Bold';
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.065em;
        color: #463649;
        margin-bottom: 4px;
        text-align: left;
    }

    .personalInfoPragraph {
        font-family: 'Poppins Regular';
        font-size: 10px;
        line-height: 17px;
        color: #717782;
        text-align: left;
    }
}