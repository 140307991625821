.notesDisplayModalContainer{
    display: flex;
    justify-content: center;

    .notesDisplayModalSection{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    @media (max-width: 450px) {
        justify-content: left;

        .notesDisplayModalSection{
            width: 100%;

        }
    }
}