.navbar {
    // position: fixed;
    // top: 0;
    padding: 19px 32px;
    gap: 10px;
    width: 100%;
    height: 81px;
    background: #FFFFFF;
    border-radius: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.sticky {
        border-radius: 0px 0px 20px 20px;
        width: 90%;
        // top: 40px;
        top: 4rem;
        z-index: 100;
        position: fixed;
        margin-left: 0;
        left: 5%;
        border: .1px solid #463649;
    }

    .logo {
        width: 94.99px;
        height: 48.95px;
    }

    .rightSide {
        display: flex;
        gap: 30px;
        align-items: center;

        .tab {
            padding: 0;
            margin: 0;
            font-family: 'Poppins Regular';
            font-weight: 400;
            font-size: 17px;
            color: #463649;
            cursor: pointer;
        }
    }
}


@media (max-width: 450px) {
    .navbar {
        padding: 17px 22px;

        .logo {
            width: 75.48px;
            height: 38.89px
        }

        .rightSide {
            .tab {
                display: none;
                visibility: hidden;
            }
        }
    }
}