.uploadImagesForSteps {
	margin-top: 0% !important;

	.label{
		color: #463649;
		font-family: "Poppins SemiBold" !important;
		font-size: 1rem;
		line-height: 2.1875rem;
		line-height: 1.4rem;
		letter-spacing: 0.1875rem;
		// text-transform: uppercase;
		margin: 0 !important;
		padding: 0 !important;
	}

	.sublabel{
		color: var(--text-grey);
		font-family: "Poppins Regular" ;
		font-size: 0.875rem;
		line-height: 1.3125rem;
		margin-bottom: 8px;
		margin-top: 4px;
	}
	
	.full {
		flex-basis: 100% !important;
	}

	// .labelTitle {
	// 	color: #463649;
	// 	font-family: "Poppins SemiBold" !important;
	// 	font-size: 1rem;
	// 	font-style: normal;
	// 	font-weight: 600;
	// 	line-height: 2.1875rem;
	// 	letter-spacing: 0.1875rem;
	// 	text-transform: uppercase;

	// 	.name {
	// 		margin-right: 7px;
	// 		font-size: 1.375rem !important;
	// 		font-family: "Poppins Regular";
	// 		color: #262626
	// 	}
	// }

	@media (max-width: 450px) { 
		title {
			margin-bottom: 11px !important;
			.name {
				font-size: 0.8125rem !important;
			}
		}
	}

		.image-btn {
			cursor: pointer;
			position: relative;
			width: 17.6875rem;
			height: 13.25rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			transition: all 0.3s;
			border-radius: 0.9375rem;
			background: transparent !important;
			box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15) !important;
			border-radius: 0.375rem;
			border: 2px dashed #D1D5DB;
			// margin-top: 0.5rem;
			.title{
				color: #3B28CC;
				text-align: center;
				font-family: "Poppins SemiBold";
				font-size: 1rem;
				line-height: 1.5rem;
				padding: 0 !important;
				margin: 0 !important;
				margin-top: 10px !important;
			}
			

			.paragraph{
				color: #6B7280;
				text-align: center;
				font-family: Inter;
				font-size: 0.875rem;
				font-style: normal;
				font-family: "Poppins Regular";
				line-height: 1.25rem;
				padding: 0 !important;
				margin: 0 !important;
			}
		}
		
		.preview-img {
			position: relative;
			border-radius: 10px;
			margin-bottom: 1rem;
			width: 17.6875rem;
			height: 13.25rem;
			border-radius: 0.375rem;
			border: 2px dashed #D1D5DB;
			.btn-delete {
				position: absolute;
				background-color: #fff;
				border-radius: 50%;
				cursor: pointer;
				top: -12px;
				right: -12px;
				width: 25px;
				height: 25px;

				svg {
					color: var(--error);
				}
			}

			.imageEditBtn{
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				top: 15px;
				left: 10px;
				width: 4rem;
				height: 1.875rem;
				border-radius: 0.625rem;
				border: 1px solid #463649;
				background: #FFF;
				color: #463649;
				font-family: "Poppins Bold";
				font-size: 0.9375rem;
				padding: 0;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 0.375rem;
			}

			@media (max-width: 450px) {
				height: 23.375rem;
			}
		}

	.imageSizeError {
		position: absolute;
		font-family: 'Poppins Medium';
		font-weight: 400;
		font-size: 0.875rem !important;
		color: #E03D32;
		margin-bottom: 5px;
		margin-top: 15px;
	}


	@media (max-width: 450px) { 
		.imageSizeError {
			font-size: 0.8125rem !important;
		}
	}
}