.mainHeading{
    font-family: 'Poppins Bold';
    font-size: 2.813rem;
    line-height: 50px;
    letter-spacing: -0.03em;
    color: var(--text-color);
    padding-bottom: 40px;
    margin: 0;
    .accent{
        color: #CC8DFF;
    }
}

@media (max-width: 450px) {
    .mainHeading{
        font-size: 1.5rem;
        line-height: 32px;
        padding-bottom: 24px;
    }
}