.socialIconsBox {
    display: flex;
    justify-content: center;
    align-items: center;

    .shareIcon {
        width: 24px;
        height: 28px;
        margin-left: 0px;
        cursor: pointer;
    }

    @media (max-width: 450px) {
        .shareIcon {
            margin-bottom: 1.25px;
        }
    }
}
