.viewSubscribersModalContainer{
    .btn{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border: none;
        outline: none;
        background: #CC8DFF;
        border-radius: 49.0909px;
        color: #FFF;
        &:hover{
            outline: none;
            color: #FFF;
            border: none;
        }

        .icon{
            font-size: 20px;
        }
    }

    @media (max-width: 450px) { 
        .btn{
            width: 25.32px;
            height: 25.34px;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            .icon{
                font-size: 14px;
            }
        }
    }
}