.tabsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;
    
    .tabContent{
        color: #463649;
        font-family: "Poppins Regular";
        font-size: 1.0625rem;
        line-height: 109%;
        cursor: pointer
    }
}