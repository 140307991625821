.addGrgFormContainer {
	.winnerSection {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
		.gameName {
			display: flex;
			flex-direction: row;
			align-items: center;

			font-family: "Recoleta Bold";
			font-size: 3.125rem;
			color: #262626;
			padding: 0;
			margin: 0;
		}

		.winnerCard {
			border-radius: 34px;
			position: relative;
			width: 280px;
			height: 192px;
			text-align: center;
			margin-bottom: 0%;
			padding: 50px 40px;
			background-image: url("../../../../../../../public/assets/stars.gif");
			background-size: cover;
			overflow: hidden;

			.title {
				font-family: "Poppins", sans-serif;
				font-weight: 700;
				font-size: 13px;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				margin-bottom: 20px;
				text-align: center !important;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.subtitle {
				font-family: "Recoleta Bold";
				font-size: 30px;
				color: #463649;
			}
		}
	}
	@media (max-width: 450px) {
		.winnerSection {
			.gameName {
				font-size: 1.75rem;
				justify-content: center;
			}

			.winnerCard {
				width: 280px;
				height: 132px;
				padding: 20px 40px;
				margin-top: 10px;

				.title {
					font-family: "Poppins", sans-serif;
					font-weight: 700;
					font-size: 13px;
					letter-spacing: 0.08em;
					text-transform: uppercase;
				}

				.subtitle {
					font-family: "Recoleta Bold";
					font-size: 24px;
					color: #463649;
				}
			}
		}
	}
}
