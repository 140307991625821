.newInputContainer {
	label{
		font-size: 1.375rem;
		font-family: "Poppins Regular";
		color: #7C88A0
	}
	.input {
		height: 54px;
        border-radius: 5px;
		border: 1px solid var(--border-color);
        font-family: 'Poppins Regular';
        font-size: 1.125rem;
        padding: 15px;
        color: #262626;

		&:focus {
			box-shadow: none !important;
        	border: 1px solid var(--border-color);
		}

		&:hover {
			box-shadow: none !important;
			border: 1px solid var(--border-color);
		}

		&:active {
			box-shadow: none !important;
			border: 1px solid var(--border-color);
		}

		&::placeholder {
			color: #9E9E9E;
			font-family: 'Poppins Regular';
			font-size: 1.125rem ;
		}

		.ant-input {
			&::placeholder {
				color: #9E9E9E;
				font-family: 'Poppins Regular';
				font-size: 1.125rem ;
			}
		}
	}

	.ant-form-item-explain-error{
		margin-top: 10px;
	}


	@media (max-width: 450px) { 
		label{
			font-size: 0.8125rem;
		}

		.input {
			height: 44px;
			font-size: 0.875rem;
			padding: 10px;
	
			&::placeholder {
				font-size: 0.875rem ;
			}
	
			.ant-input {
				&::placeholder {
					font-size: 0.875rem ;
				}
			}
		}

		.ant-form-item-explain-error{
			font-size: 0.8125rem;
		}
	}
}