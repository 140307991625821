.viewVillieUpdatesModal{
    height: max-content !important;
    overflow-y: visible;
}
.viewVillieUpdatesModalContainer{
    display: flex;
    justify-content: center;

    .viewVillieUpdatesModalSection{
        width: 65%;
        display: flex;
        justify-content: center;
        align-items: center;

        .heading{
            text-align: center;
        }

        .leftSide{
            width: 100%;
            .headline{
                font-family: 'Poppins Bold';
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.03em;
                color: #463649;
                margin-bottom: 16px;
    
                .backBtn{
                    margin-bottom: 3px;
                    padding-right: 24px;
                    cursor: pointer;
                }
            }
        
            .image{
                width: 100%;
                height: auto;
                border-radius: 10px;
            }
        }

        .rightSide{
            width: 100%;
            
            .submitBtn{
                width: 100%;
            }
        }
        
    }

    @media (max-width: 450px) {
        justify-content: left;

        .viewVillieUpdatesModalSection{
            width: 100%;

            .heading{
                text-align: left;
            }

            .leftSide{
                .image{
                    width: 100%;
                }
            }
            
            .rightSide{
                width: 92%;
                margin: 24px 4% 0 4%;
            }
        }
    }
}