.noDataContainer{
    .ant-empty-description{
        font-family: "Poppins Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 1.375rem;
        color: var(--text-color-1);
        padding-bottom: 10px;
    }

    .actionBtn {
        width: max-content;
        height: 40px;
        border: 1px solid var(--secondary);
        background-color: var(--secondary);
        color: #FFF;
        outline: none !important;
        font-family: "Poppins Regular";
        font-size: 1.25rem !important;
        border-radius: 30px;
        font-weight: 500;
        font-size: 1.375rem;

        &:hover {
            border: 1px solid var(--secondary);
            color: #FFF;
        }
    }

    @media (max-width: 450px) {
        .ant-empty-description{
            font-size: .875rem;
        }

        .ant-empty-image{
            margin-bottom: -20px;
            .ant-empty-img-default{
                width: 80px;
                height: 80px;
            }
        }
        
    }
}