.collapseContainer{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .panel{
        border-radius: 0.625rem;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);

        .topPanel{
            cursor: pointer;
            padding: 1rem;
            height: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .headerPanel{
                color: #463649;
                font-family: "Poppins SemiBold";
                font-size: 1rem;
                line-height: 130%;
            }

            .comingSoon{
                color: #494C52;
                font-family: "Poppins Bold";
                font-size: 0.75rem;
                line-height: 0.9375rem;
                letter-spacing: 0.04875rem;
                text-transform: uppercase;
            }
    
            .iconPanel{
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
            }
        }
        
        .bottomPanel{
            display: none;
            visibility: hidden;
            padding: 1rem;
            padding-top: 0rem !important;
            .descriptionPanel{
                margin-bottom: 1rem;
                color: #494C52;
                font-family: "Poppins Regular";
                font-size: 0.875rem;
                line-height: 150%;
            }
        }

        .showBottomPanel{
            display: block;
            visibility: visible;
        }
    }

    @media(max-width: 450px){
        gap: .75rem;
    }
}