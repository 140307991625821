@import '~antd/dist/antd.min.css';
@import './shared/styles/colors.css';

body{
    overflow-x: hidden;
    scroll-behavior: smooth !important;
}

* {
    scroll-behavior: smooth !important;
}

p{
    margin: 0;
    padding: 0;
}

/* =========== colors =========== */
.c-bg-w{
    background-color: #FFFFFF !important;
}

.c-bg-faf3f78a {
    background: #faf3f78a !important;
}

.c-fg-7C88A0{
    color: #7C88A0 !important;
}

/* =========== cursors =========== */
.c-p{
    cursor: pointer;
}

/* =========== display =========== */
.d-v-c{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.d-h-c{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 16px
}

.d-h-sb{
    display: flex;
	justify-content: space-between;
    align-items: center;
}


/* =========== margin =========== */
.m-t-1-p{
    margin-top: 1%;
}

.m-t-2-p{
    margin-top: 2%;
}

.m-t-3-p{
    margin-top: 3%;
}

.m-t-65-px{
    margin-top: 65px;
}

.m-b-5-p{
    margin-bottom: 5%;
}

.m-b-8-p{
    margin-bottom: 8%;
}

.m-tb-25-px{
    margin: 25px 0;
}

.m-tb-30-px{
    margin: 30px 0;
}

/* =========== padding =========== */
.p-t-4-p{
    padding-top: 4%;
}

/* =========== text =========== */
.t-d-u{
    text-decoration-line: underline
}

.t-a-c{
    text-align: center;
}

/* =========== width =========== */
.w-m{
    width: max-content !important;
}

.w-100-p{
    width: 100% !important
}

.w-90-p{
    width: 90% !important
}

.w-48-p{
    width: 48% !important
}


.w-40-p{
    width: 40% !important
}

.w-10-p{
    width: 10% !important
}

.w-60-p{
    width: 60% !important
}