.stripeFormContainer{
    text-align: left;
    font-size: 0.8125rem;
    line-height: 19.5px;

    .label{
        letter-spacing: -0.7px;
        font-family: "Poppins Regular";
        font-size: 1rem;
        margin-bottom: 3%;

        .error{
            padding-top: 30px !important;
            color: var(--error);
            font-size: 0.9375rem;
            font-family: "Poppins Regular";
        }
    }


    .stepInput{
        border-radius: 8px;
        height: 48px;
        border: 1px solid rgb(228, 228, 228);
        outline: none !important;
        box-shadow: none !important;
        font-family: "Poppins Regular";
        font-size: 1.125rem;
        margin-bottom: 15px;
        &:focus {
            box-shadow: rgb(228, 228, 228) !important;
            border-color: rgb(228, 228, 228) !important;
            outline-color: rgb(228, 228, 228) !important;
            border: 1px solid rgb(228, 228, 228);
        }
      
        &:hover {
            box-shadow: rgb(228, 228, 228) !important;
            border-color: rgb(228, 228, 228) !important;
            outline-color: rgb(228, 228, 228) !important;
            border: 1px solid rgb(228, 228, 228);
        }
      
        &:active {
            box-shadow: rgb(228, 228, 228) !important;
            border-color: rgb(228, 228, 228) !important;
            outline-color: rgb(228, 228, 228) !important;
            border: 1px solid rgb(228, 228, 228);
        }

        &::placeholder {
			color: rgba(0, 0, 0, 0.45);
			font-family: 'Poppins Regular' !important;
			font-size: 1rem !important;
		}

		.ant-input {
			&::placeholder {
				color: #00000073;
				font-family: 'Poppins Regular' !important;
				font-size: 1rem !important;
			}
		}
    }
    
    .error{
        margin-top: 0px;
        color: var(--error);
        font-size: 0.9375rem;
        font-family: "Poppins Regular";
    }

    .customLabel {
        letter-spacing: -0.7px;
        display: block;
        margin-bottom: 15px;
        margin-top: 30px;
        font-family: "Poppins Regular";
        font-size: 1rem;

        .error{
            padding-top: 30px !important;
            color: var(--error);
            font-size: 0.9375rem;
            font-family: "Poppins Regular";
        }
    }

    .submitBtn{
        align-items: center;
        border: 1px solid var(--secondary);
        border-radius: 10px;
        color: #FFFFFF;
        background-color: var(--secondary);
        cursor: pointer;
        font-family: "Poppins Regular";
        font-size: 1.125rem;
        font-weight: 700;
        height: 48px;
        justify-content: center;
        max-width: 100%;
        padding: 10px;
        width: 100%;
        margin-bottom: 5%;

        &:hover {
            border: 1px solid var(--secondary);
            background-color: var(--secondary);
            color: #FFF;
        }

        &:focus {
            border: 1px solid var(--secondary);
            background-color: var(--secondary);
            color: #FFF;
        }

        &:active {
            border: 1px solid var(--secondary);
            background-color: var(--secondary);
            color: #FFF;
        }

        &:visited {
            border: 1px solid var(--secondary);
            background-color: var(--secondary);
            color: #FFF;
        }

        &:focus-visible {
            border: 1px solid var(--secondary);
            background-color: var(--secondary);
            color: #FFF;
        }

        &:focus-within {
            border: 1px solid var(--secondary);
            background-color: var(--secondary);
            color: #FFF;
        }
    }

    .primaryDisabled{
        background-color: #e9c6fc;
        cursor: no-drop;
    }

    .secondaryDisabled{
        background-color: var(--secondary);
        cursor: no-drop;
    }

    .contributionAmountTitle{
        font-family: 'Poppins Medium';
        font-size: 1.375rem;
        color: #9E9E9E;
        padding: 0;
        margin: 0;
        padding-bottom: 20px;
        text-align: left;
    }

    .contributionAmount{
        font-family: 'Recoleta Bold';
        font-weight: 700;
        font-size: 2.375rem;
        color: #262626;
        padding: 0;
        margin: 0;
        margin-bottom: 40px;
        text-align: left;
        
        .small{
            font-family: 'Poppins Regular';
            font-size: 1.125rem;
            color: #9E9E9E;
        }
    }
}

@media (max-width: 450px) { 
    .stripeFormContainer{
        margin: 4% 0;

        .stepInput{
            margin-bottom: 10px;
        }

        .error{
            font-size: 13px;
        }

        .customLabel {
            margin-top: 15% !important;
        }

        .submitBtn{
            width: 100% !important;
        }

        .contributionAmountTitle{
            font-size: 0.8125rem;
            padding-bottom: 10px;
        }

        .contributionAmount{
            font-size: 1.5rem;
            margin-bottom: 20px;
            .small{
                font-size: 0.875rem;
            }
        }
    }
}