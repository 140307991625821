.newFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 32px;
    height: 65px;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(217, 171, 254, 0.15);
    border-radius: 20px;
    width: 90%;
    .logo{
        width: 63px;
        height: 32.46px;
    }
    
    .rightSide{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 16px;

        .footerTab{
            cursor: pointer;
            font-family: 'Poppins SemiBold';
            font-size: 14px;
            color: #463649;

            a{
                font-family: 'Poppins SemiBold';
                font-size: 14px;
                color: #463649;
            }
        }
    
        .footerHelperText{
            font-family: 'Poppins Regular';
            font-size: 14px;
            color: #463649;
        }
    }

    @media (max-width: 450px) { 
        flex-direction: column;
        height: max-content;
        width: 99%;
        gap: 2.5rem;
        padding: 16px 24px;

        
        .logo{
            width: 4.71744rem;
            height: 2.43063rem;
        }

        .rightSide{
            flex-direction: column;
            gap: 4px;
        }
    }
}
