.guessWhoContainer{
    width: 22.4375rem;
    height: max-content;
    padding: 2rem 1.7rem 2.63rem 1.7rem;

    .result{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        .box{
            width: 172px;
            height: 111px;
            background-color: #FFF16F;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0.9375rem;
            border-radius: 0.625rem;

            .icon{

            }

            .text{
                color:  #463649;
                font-family: "Poppins SemiBold";
                font-size: 1.125rem;
            }
        }
    }

    // ---------------------------
    .chartSectionArea {
        padding: 10px 25px 10px 10px;
        width: 100%;
        height: 124.59px;
    
        background: #F8F9FA;
        border-radius: 0.625rem;

        .bar{
            border-radius: 24px;
            background: #5263FF;
            box-shadow: 0px 8px 16px 0px rgba(54, 16, 166, 0.20);
            height: 28px;
    
            margin-top: 1.06rem;
            margin-bottom: 1.19rem;
    
            .internalBar{
                border-radius: 1.5rem;
                background: #D9ABFE;
                height: 28px;
            }
        }

        .parentName{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                color: #463649;
                font-family: "Poppins Medium";
                font-size: 0.8125rem;
            }
        }
    
        @media(max-width: 450px) {
            padding: 10px 5px;
            height: 95px;
        }
    }

    // ---------------------------
    .showAllResults{
        margin-top: 1.12rem;
        .heading{
            color: #7C88A0;
            text-align: center;
            font-family: "Poppins Medium";
            font-size: 0.8125rem;
            font-weight: 500;
            margin-bottom: .5rem;
        }

        .box{
            padding: 10px;
            width: 100%;
            background: #F8F9FA;
            border-radius: 0.625rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .individual{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;

                .q{
                    color: #7C88A0;
                    font-family: "Poppins Regular";
                    font-size: 0.75rem;
                    width: 50%;
                }

                .winner{
                    color: #000;
                    font-family: "Poppins SemiBold";
                    font-size: 1rem;
                    width: 40%;
                    text-align: right;
                }
            }
        }

    }

    // ---------------------------
    .topRow{
        display: flex;

        img{
            cursor: pointer;
        }

        .questionNumber{
            flex: 1;
            text-align: center;
            color: #463649;
            font-family: "Poppins Bold";
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 0.9375rem;
            letter-spacing: 0.04875rem;
            text-transform: uppercase;
        }
    }

    .progressBar{
        border-radius: 1.5rem;
        background: #7646FE;
        box-shadow: 0px 8px 16px 0px rgba(54, 16, 166, 0.20);
        height: 0.5rem;

        margin-top: 1.06rem;
        margin-bottom: 1.19rem;

        .internalBar{
            border-radius: 1.5rem;
            background: #FFF16F;
            height: 0.5rem;
        }
    }

    .question{
        color: #463649;
        text-align: center;
        font-family: "Poppins SemiBold";
        font-size: 1.125rem;
        line-height: 1.6875rem;
        margin-bottom: 1.19rem;
    }

    .selectionBox{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .select{
            cursor: pointer;
            width: 103px;
            height: 83.91;
            border-radius: 0.625rem;
            border: 2px solid rgba(255, 255, 255, 0.40);
            background: #FFF;
            box-shadow: 0px 8px 20px 0px rgba(87, 47, 255, 0.30);
            display: flex;
            justify-content: center;
            align-items: center; 
            flex-direction: column;   
            padding: 0.5rem 0;
            
            p{
                color: #463649;
                font-family: "Poppins Medium";
                font-size: 0.8125rem;
                font-style: normal;
                line-height: 140%;
            }

            .iconImage{
                font-size: 2.2rem;
                margin-top: 10px;
                margin-bottom: 10px;
                font-family: "Poppins Bold";
            }
        }
    }

    .error{
        color: #FF0000;
        text-align: center;
        font-family: "Poppins Regular";
        font-size: 0.75rem;
        font-style: normal;
        margin-top: 1.2rem;
        margin-bottom: -0.5rem;
    }

    // ---------------------------
    .title{
        color: #463649;
        text-align: center;
        font-family: "Poppins Bold";
        font-size: 2rem;
        font-style: normal;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        margin-bottom: .63rem;
    }

    .subtitle{
        color: #463649;
        text-align: center;
        font-family: "Poppins Bold";
        font-size: 0.75rem;
        font-style: normal;
        line-height: 0.9375rem;
        letter-spacing: 0.04875rem;
        text-transform: uppercase;
    }

    .paragraph{
        color: #463649;
        text-align: center;
        font-family: "Poppins Regular";
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6875rem; 
        margin: 2rem 0;
    }

    .paginations{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.5rem;
    }

    // ---------------------------
    .bottomBtn{
        width: 85%;
        position: absolute;
        bottom: -20px;
                
        margin-top: 1.63rem;
    }

    @media (max-width: 450px){
        width: 17.4375rem;
        padding: 1.5rem 1.5rem 0 1.5rem;


        .paginations{
            margin-bottom: 1.5rem;
        }
    
        .bottomBtn{
            margin-bottom: -20px;
        }
    }
}