.infoModal{
    .ant-modal-content{
        width: 45%;
        padding: 30px;
        text-align: center;
        
        @media (max-width: 450px){
            width: 100%;
        }
    }
}