.chartSectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px 10px 10px;

    width: 100%;
    height: 124.59px;

    background: #F8F9FA;
    border-radius: 100px 10px 10px 100px;

    .votesCount {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .title {
            font-family: 'Poppins Regular';
            font-size: 1.125rem;
            color: var(--text-color-1);
            margin: 0
        }

        .subtitle {
            font-family: 'Recoleta Bold';
            font-size: 1.5rem;
            text-transform: capitalize;
            color: #000000;
            margin: 0
        }
    }

    .moneyRaised {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .title {
            font-family: 'Poppins Regular';
            font-size: 1.125rem;
            color: var(--text-color-1);
            margin: 0
        }

        .subtitle {
            font-family: 'Recoleta Bold';
            font-size: 1.5rem;
            text-transform: capitalize;
            color: #000000;
            margin: 0
        }
    }

    @media(max-width: 450px) {
        justify-content: space-evenly;
        padding: 10px 5px;
        height: 95px;
        border-radius: 10px;

        .votesCount {
            .title {
                font-size: 0.875rem;
            }

            .subtitle {
                font-size: 1.125rem;
            }
        }

        .moneyRaised {
            .title {
                font-size: 0.875rem;
            }

            .subtitle {
                font-size: 1.125rem;
            }
        }
    }
}