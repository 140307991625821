.notificationContainer {
    position: fixed;
    // width: 300px;
    z-index: 1000 !important;
    width: max-content !important;

    &.topRight {
        right: 0px;
        top: 20px;
    }
    &.topLeft {
        left: 20px;
        top: 20px;
    }
    &.bottomRight {
        right: 20px;
        bottom: 20px;
    }
    &.bottomLeft {
        left: 20px;
        bottom: 20px;
    }
}


.notification {
    margin-bottom: 10px;
    padding: 0.5rem;
    background-color: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-family: "Poppins SemiBold";
    font-size: 1rem;
    line-height: 1.875rem;
    letter-spacing: -0.03125rem;
    
    .notification-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
    }

    .icon {
        width: 24px;
        height: 24px;
        margin-right: .5rem;
    }

    &.success {border: 1px solid #FCE4F9}
    &.error { background-color: #f44336;}
    &.info { background-color: #2196f3; }
    &.warning { background-color: #ff9800; }

    .closeBtn {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-left: 0.5rem;
    }
}
