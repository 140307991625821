.mainLabel{
    font-family: "Poppins Bold";
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    color: #463649;
    padding-bottom: 16px !important;
}

@media (max-width: 450px) {
    .mainLabel{
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.065em;
    }
}