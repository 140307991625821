.dropdownContainer{
    .dropDownLabel{
        font-family: 'Poppins SemiBold';
        font-style: normal;
        font-size: 1rem;
        // line-height: 0rem;
        letter-spacing: 0.1875rem;
        text-transform: uppercase;
        color: #463649;
        padding-bottom: .3rem;
    }
    .customBDropdown{
        padding: 15px;
        height: 60px;
        border-radius: 10px;
        border: 1px solid #463649 !important;
        background: rgba(250, 243, 247, 0.54) !important;
        color: #463649 !important;
        font-family: "Poppins Regular";
        font-size: 1.125rem;
        line-height: 1.875rem;
        letter-spacing: -0.03125rem;
        outline: none !important;
        box-shadow: none !important;
        width: 100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
}


.ant-dropdown-menu {
    border-radius: 0.625rem;
    border: 1px solid #463649;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
    padding: .7rem 3px !important;

    
    .ant-dropdown-menu-title-content{
        color: #463649 !important;
        font-family: "Poppins Regular" !important;
        font-size: 1.125rem !important;
        line-height: 1.875rem !important;
        letter-spacing: -0.03125rem !important;
    }
    
    @media (max-width: 450px) { 
       
    }
    
}

.ant-dropdown-menu-item{
    background-color: transparent !important;
}