:root {
    --text-color-1: #7C88A0;
    --border-color: rgba(204, 141, 255, 0.25);

    --primary: #cc8dff;
    --secondary: #5363FF;
    --tertiary: #463649;
    --text-grey: #949393;
    --error: #ff3333;

    --website-bg: #FFF;
    --website-bg-opacity: hsla(234, 100%, 66%, 0.102);
    --website-divider: rgba(82, 99, 255, 0.25);
    
    /* --website-bg-opacity: rgba(82, 99, 255, 0.1); */
    /* --website-name-bg: rgba(82, 99, 255, 10%); */

    --grey: #F1F1F2;
    --cream: #908f8fcc;
    --white: #ffffff;
    --black: #020303;
    --delete: #ff0000;
    --link: #0062ff;
    --errorShadow: #D9042926;


    --theme-color: #FBF7FF;

    --btn-background: #FFF16F;
    --btn-color: #463649;
    --text-color: #463649;
    --preview-span-color: #9745DA;
    

    --background-image: none;
    --border-color: #7C88A0;
    --button-background-color: #cc8dff;
    --button-border-color: rgba(217, 171, 254, 0.25);
}