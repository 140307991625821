.modalContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    width: 100% !important;
    min-height: 95% !important;
    max-height: max-content !important;
    top: 5%;
    overflow: hidden !important;
    
    .ant-modal-title{
        font-family: "Poppins Regular" !important;
        font-size: 1.25rem !important;
    }

    .ant-modal-content{
        border-radius: 30px 30px 0px 0px;
        padding: 80px 20px 40px;
        width: 90%;
        height: 100%;
        overflow: hidden;
    }

    .closeBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        background: rgba(38, 38, 38, 0.15);
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .body{
        .title{
            color: #262626;
            font-size: 2.625rem;
            font-family: "Poppins SemiBold";
            font-weight: 600;
            margin-bottom: 20px;
        }
    }

    @media (max-width: 450px){
        top: 10%;
        height: max-content !important;
        .ant-modal-content{
            padding: 0px;
            padding: 30px 11px !important;
            width:  100%;
            border-radius: 30px;

            .ant-modal-body {
                padding: 0;
            }
        }
    
        .closeBtn{
            z-index: 100;
            right: 11px;
            top: 20px
        }

        .body{
            .title{
                font-size: 1.375rem;
                margin-bottom: 10px;
            }
        }
    }
}