.babyFundsFormContainer {
    .new-ui-box {
        border: 2px solid rgb(218, 218, 218);
        padding: 20px;
        border-radius: 15px;
        margin-bottom: 15px;
    }
    .heading {
        color: #463649;
        font-family: 'Poppins SemiBold';
        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacing: 0.1875rem;
        text-transform: uppercase;
    }

    .btnBoxFundContainer {
        display: flex;
        gap: 1.69rem;
        margin: 0.5rem 0;

        .btnFund {
            border-radius: 0.625rem;
            width: 4.75rem;
            height: 1.875rem;
            padding: 0.625rem 1.75rem;
            justify-content: center;
            align-items: center;
            color: #463649;
            text-align: center;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            font-family: 'Poppins Bold';
            font-size: 0.9375rem;
            text-transform: uppercase;
            border: 1px solid #463649;
            background: #fff;
        }

        .isBtnSeclected {
            border: 1px solid #463649;
            background: rgba(204, 141, 255, 0.23);
        }
    }

    .registryBoxFunds {
        margin-top: 1.75rem;
        border: 2px solid;
        border-radius: 15px;
        padding: 20px;
        .label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: bolder;
            font-size: 20px;
            line-height: 1.25rem;
            margin-bottom: 0.5rem !important;
        }
        .text {
            color: #463649;
            margin-top: 20px;
            margin-bottom: 1rem;
            font-family: "Poppins Regular";
            font-size: 0.875rem;
            line-height: 150%;
        }
        .registryArea {
            border-radius: 0.625rem;
            border: 1px solid #463649;
            background: rgba(250, 243, 247, 0.54);
            padding: 0rem 15px;
            .topAreaRegistry {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 3.75rem !important;

                .headingRegistryArea {
                    color: #463649;
                    font-family: 'Poppins Regular';
                    font-size: 1.125rem;
                    line-height: 1.875rem;
                    letter-spacing: -0.03125rem;
                }
                .iconRegistryArea {
                    width: 1.5rem;
                    height: 1.5rem;
                    cursor: pointer;
                }
            }

            .isTopAreaRegistryVisible {
                margin-bottom: 1.69rem;
            }

            .bottomAreaRegistry {
                display: none;
                visibility: hidden;
                // .label{
                //     font-family: 'Poppins SemiBold';
                //     font-style: normal;
                //     font-size: 1rem;
                //     line-height: 0rem;
                //     letter-spacing: 0.1875rem;
                //     text-transform: uppercase;
                //     color: #463649;
                //     padding-bottom: 0.7rem;
                // }
            }

            .isBottomAreaRegistryVisible {
                display: block;
                visibility: visible;
            }
        }

        .isRegistryAreaVisible {
            padding: 0rem 15px;
            padding-bottom: 1.06rem;
            background: #fff;
        }
    }

    .registryDisplayBox {
        margin: 1.5rem 0;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        flex-wrap: wrap;
        .registry {
            position: relative;
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 15%;
            border-radius: 0.625rem;
            background: #fff;
            border: 1px solid #463649;
            box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);

            .deleteBtn {
                top: -16px;
                right: -16px;
                position: absolute;
                width: 2.1875rem;
                height: 2.1875rem;
                cursor: pointer;
            }

            img {
                width: 70%;
                object-fit: contain;
            }
        }
    }
}
