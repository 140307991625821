.eventSectionContainer{
    .eventsBox{
        display: flex;
        align-items: center;
        gap: 2%;
        // justify-content: space-between;
    }
    .spanText{
        color: var(--preview-span-color);
    }

    .lastRow{
        display: flex;
        justify-content: end;
    }

    @media (max-width: 450px) {
        .heading{
            padding-bottom: 24px !important;
        }

        .eventsBox{
            flex-direction: column;
            gap: 4% !important;
        }
    }
}