.decisionBtnContainer {
    margin-bottom: 1.3rem;
    .labelDecision {
        color: #463649;
        font-family: 'Poppins SemiBold';
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        letter-spacing: 0.1875rem;
        text-transform: capitalize;
    }
    .btnBoxDecision {
        margin-top: 0.5rem;
        display: flex;
        gap: 1.69rem;
        .btnD {
            border-radius: 0.625rem;
            width: 4.75rem !important;
            height: 1.875rem;
            padding: 0.625rem 1.75rem;
            justify-content: center;
            align-items: center;
            color: #463649;
            text-align: center;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            font-family: 'Poppins Bold';
            font-size: 0.9375rem;
            // text-transform: capitalize;
            border: 1px solid #463649;
            background: #fff;
        }

        .isBtnSeclected {
            border: 1px solid #463649;
            background: rgba(204, 141, 255, 0.23);
        }
    }

    @media (max-width: 450px) {
        // .labelDecision{
        //     line-height: 1.5625rem;
        // }
    }
}
