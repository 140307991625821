.productsContainer{
    .product{
        width: 100%;
        height: 253px;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        padding: 3% 7.5% 0% 7.5%;
        overflow: hidden;

        .btnDelete{
            position: absolute;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
            top: -10px;
            right: 2px;
            width: 25px;
            height: 25px;

            svg {
                color: var(--error);
            }
        }

        .image{
            width: 100%;
            height: 130px;
            object-fit: cover;
            border-radius: 10px;
        }

        .brandName{
            font-family: 'Poppins Bold';
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #463649;
            width: 100%;
            height: 43px;
        }

        .priceArea{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            
            .price{
                font-family: 'Poppins Bold';
                font-size: 16px;
                line-height: 27px;
                color: #8088d0;
            }

            .btn{
                cursor: pointer;
            }
        }
    }

    .registry{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0.9375rem;
        border-radius: 0.625rem;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
        margin-bottom: 1rem;
        cursor: pointer;
        .name{
            font-family: 'Poppins Bold';
            font-size: 16px;
            line-height: 130%;
            color: #262626;
        }
    }

    @media (max-width: 450px){
        .product{
            height: 251px;
            padding: 8% 7.5%;
            .image{
                // width: 94%;
                height: 112px;
                border-radius: 10px;
            }
            .brandName{
                // display: flex;
                // align-items: center;
                margin-top: 5%;
                font-family: 'Poppins SemiBold';
                font-size: 16px;
                line-height: 18px;
                height: 72px;
            }
            .priceArea{
                .btn{
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }
}
