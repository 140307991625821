.addPassCodeModalContainer {
	.title{
		color: #463649;
		font-family: "Poppins Bold";
		font-size: 2.8125rem;
		font-style: normal;
		line-height: 1rem;
		letter-spacing: -0.08438rem;
	}

	.subtitle{
		color: #6B7280;
		font-family: "Poppins Regular";
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 2rem;
		margin-bottom: 1.5rem;
	}
	.passCodeBox {
        display: flex;
		flex-direction: column;

        .passCodeForm{
			width: 50%;
		}
	}

    @media (max-width: 450px) {
		width: 95%;
		max-height: max-content;
		margin: 3% 2.5%;
		border-radius: 30px;
		padding: 4% 2.5%;

		.passCodeBox{
			margin-bottom: 4.06rem;

			.passCodeForm{
                width: 100%;
			}
		}
	}
}
