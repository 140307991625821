.topBarContainer {
	display: flex;
	width: 100%;
	height: 64px;
	background: #9745da;

	&.sticky {
		top: 0rem;
		z-index: 100;
		position: fixed;
	}

	.leftContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 90%;
		text-align: center;
		font-family: "Poppins Bold";
		font-size: 0.75rem;
		font-style: normal;
		padding-left: 2.75%;

		.upgradePlanBtn{
			display: none;
			visibility: hidden;
		}

		.titleText {
			color: #fff;
			font-family: "Poppins SemiBold";
			font-size: 24px;
		}

		.url {
			color: #fff;
			font-family: "Poppins Regular";
			font-size: 0.875rem;
			line-height: 150%;
		}

		.editurl {
			color: #fff !important;
		}
	}

	.rightContainer {
		display: flex;
		justify-content: flex-center;
		align-items: center;
		width: 10%;
		margin-right: 2.75%;
		margin-left: 2.75%;

		.upgradeBtn{
			padding: 8px 18px;
			cursor: pointer !important;
			.icon{
				margin-bottom: 2px;
				margin-right: 10px;
			}
		}
	}

	@media (max-width: 450px) {
		.leftContainer {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: row;
			gap: 20px;
			padding-right: 2.75%;

			.upgradePlanBtn{
                width: 44px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
				visibility: visible;
                border-radius: 0.625rem;
				box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
            }

			.titleText {
				font-size: 0.75rem ;
			}
			.btn {
				width: 120px !important;
			}
		}
		.rightContainer {
			visibility: hidden;
			display: none;
		}
	}
}