.cancelPremiumModal{
    justify-content: center;

    .ant-modal-content{
        width: 19.5625rem;
        padding: 0rem;
        border-radius: 0.625rem;
        background: #FFF;
    }
}

.cancelPremiumProductsModalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    .btnDelete{
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        top: -15px;
        right: -15px;
        width: 25px;
        height: 25px;

        svg {
            color: var(--error);
        }
    }

    .heading{
        color: #463649;
        text-align: center;
        font-family: "Poppins SemiBold";
        font-size: 1rem;
        line-height: 130%;
    }

    .subheading{
        color: #494C52;
        text-align: center;
        font-family: "Poppins Regular";
        font-size: 0.875rem;
        line-height: 150%;
        margin-top: -0.5rem;
    }


    @media (max-width: 450px){
        .btnDelete{
            top: -20px;
            right: 0px;
        }
    }
}

