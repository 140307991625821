.questionsBoxContainer {
    margin-top: 10px;
    width: 100%;
    .questionBox {
        width: 100% !important;
        height: 3.75rem;
        border-radius: 0.625rem;
        border: 1px solid #463649;
        background: rgba(250, 243, 247, 0.54);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 .94rem 0 1.56rem;
        margin-top: 10px;

        .questionEditInput{
            width: 95%;
            flex: 1;
        }

        .question{
            font-family: "Poppins SemiBold";
            font-style: normal;
            font-size: 1rem;
            line-height: 1.4rem;
            letter-spacing: 0.1rem;
            width: max-content;
            color: #463649;
        }
        

        .editIcon{
            cursor: pointer;
        }
        
    }
}