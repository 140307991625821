.preview{
    width: 100%;
    background: var(--theme-color);
    border-radius: 10px;
    padding: 3%;
    overflow: hidden;

    .navbar{
        padding: 2%;
        width: 100%;
        height: 61px;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: 74.99px;
            height: 48.95px;
        }

        .profileImage{
            cursor: pointer;
            width: 2.625rem;
            height: 2.625rem;
        }
    }

    .navbarForThemes{
        padding: 2% 4%;
        height: 30px;
        border-radius: 8px;

        .logo {
            width: 30%;
            height: 48.95px;
        }

        .loginBtn{
            color: #463649;
            font-family: "Poppins Regular";
            font-size: .6rem;
        }
    }

    .navbarForDashboard{
        height: 51px;

        .loginBtn{
            font-family: "Poppins Bold";
            font-size: 1rem;
        }
    }

    .previewHero {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4%;
        .previewLeftSide {
            width: 40%;
            .headline {
                font-family: 'Poppins Bold';
                font-size: 1.5rem;
                line-height: 2rem;
                letter-spacing: -0.03em;
                color: #463649;
                margin-bottom: 18px;
            }

            .discription {
                font-family: 'Poppins Regular';
                font-size: 16px;
                line-height: 20px;
                margin-top: 18px;
                color: #717782;
                display: flex;
                flex-direction: column;
                gap: 24px;
                white-space: pre-wrap;

                span:nth-child(2) {
                    display: none;
                }

                .ant-typography-expand {
                    font-family: 'Poppins Regular';
                    font-size: 10px;
                    line-height: 17px;
                    color: #463649
                }
            }
        }

        .rightSide {
            width: 40%;
            
            
            .image {
                width: 300px;
                height: 220px;
                border-top-left-radius: 40px;
                border-bottom-left-radius: 40px;
                object-fit: cover; 
            }
        }
    }

    .previewHeroForTheme {
        .previewLeftSide {
            width: 40%;
            .headline {
                font-size: .6rem;
                line-height: .7rem;
                margin-bottom: 3px;
            }

            .discription {
                font-size: .4rem;
                line-height: .4rem;
                margin-top: 5px;

                .ant-typography-expand {
                    font-size: .4rem;
                    line-height: .4rem;
                }
            }

            .jorBtn{
                padding: 4px 2px ; 
                line-height: 0 ; 
                height: max-content;
                font-size: .3rem ;
                border-radius: 2px;
            }
        }

        .rightSide {
            width: 40%;
            
            .image {
                width: 100px;
                height: 80px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                object-fit: cover; 
            }
        }
    }

    .previewHeroForDashboard {
        .previewLeftSide {
            width: 40%;
            .headline {
                font-size: 1rem;
                line-height: 1.5rem;
                margin-bottom: 10px;
            }

            .discription {
                font-size: .7rem;
                line-height: 1rem;
                margin-top: 10px;

                .ant-typography-expand {
                    font-size: .4rem;
                    line-height: .4rem;
                }
            }

            .jorBtn{
                padding: 10px; 
                line-height: 0 ; 
                height: max-content;
                font-size: .6rem ;
                border-radius: 2px;
            }
        }

        .rightSide {
            width: 40%;
            
            .image {
                width: 200px;
                height: 160px;
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
                object-fit: cover; 
            }
        }
    }

    @media (max-width: 450px) {

            .navbar{
                padding: 10px 12px;
                height: 41px;
                border-radius: 8px;
                .logo {
                    width: 54.99px;
                    height: 48.95px;
                }

                .profileImage{
                    cursor: pointer;
                    width: 1.625rem;
                    height: 1.625rem;
                }
            }


            .navbarForThemes{
                padding: 2% 4%;
                height: 25px;
                .logo {
                    width: 23%;
                    height: 48.95px;
                }
        
                .loginBtn{
                    font-size: .5rem;
                }
            }

            .navbarForDashboard{
                .loginBtn{
                    font-family: "Poppins Bold";
                    font-size: 1rem;
                }
            }

            .previewHero {
                .previewLeftSide {
                    .headline {
                        font-size: .9rem;
                        line-height: .9rem;
                        margin-bottom: 10px;
                    }
        
                    .discription {
                        font-size: .4rem;
                        line-height: .7rem;
                        margin-top: 20px;
                        gap: 4px;

                        .ant-typography-expand {
                            font-size: .4rem;
                            line-height: .4rem;
                        }
                    }

                    .jorBtn{
                        padding: 4px 2px ; 
                        line-height: 0 ; 
                        height: max-content;
                        font-size: .3rem ;
                        border-radius: 2px;
                    }
                }
        
                .rightSide {
                    width: 45%;
                    .image {
                        width: 180px;
                        height: 180px;
                    }
                }
            }

            .previewHeroForTheme {
                .previewLeftSide {
                    .headline {
                        font-size: .5rem;
                        line-height: .7rem;
                        margin-bottom: 7px;
                    }
        
                    .discription {
                        margin-top: 10px;
                    }
        
                    .jorBtn{
                        padding: 4px 2px ; 
                        line-height: 0 ; 
                        height: max-content;
                        font-size: .3rem ;
                        border-radius: 2px;
                    }
                }
        
                .rightSide {
                    width: 40%;
                    
                    .image {
                        width: 100px;
                        height: 80px;
                        border-top-left-radius: 20px;
                        border-bottom-left-radius: 20px;
                        object-fit: cover; 
                    }
                }
            }

            .previewHeroForDashboard {
                .previewLeftSide {
                    .headline {
                        line-height: 1.2rem;
                        margin-bottom: 15px;
                    }
        
                    .discription {
                        margin-top: 15px;
                    }
        
                    .jorBtn{
                        padding: 8px 2px ; 
                        font-size: .6rem ;
                    }
                }
        
                .rightSide {
                    width: 45%;
                    
                    .image {
                        width: 200px;
                    }
                }
            }
    }
}