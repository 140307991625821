.buttonContainer {
	width: max-content;
	height: 43px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 28px;
	border-radius: 0.625rem;
	font-family: "Poppins Bold";
	font-size: 15px;
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	line-height: 22px;
	color: #463649;
}

.ant-btn[disabled] {
	color: #463649 !important;
	border-radius: 0.625rem !important;
	width: max-content !important;
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
	border: none !important;
	background-color: inherit !important;
	color: inherit !important;
	padding: 0 !important;
	margin: 0 !important;
	margin-top: -20% !important;
	padding-top: -20% !important;
	width: 100% !important;
	cursor: not-allowed !important;
	font-family: "Poppins Bold" !important;
	font-size: 15px !important;
}

.base1Btn {
	border: 3px solid var(--primary);
	background-color: var(--primary);
	color: #fff;

	&:hover {
		background-color: var(--primary);
		border: 3px solid var(--primary);
		color: #fff;
	}
	&:visited {
		background-color: var(--primary);
		border: 3px solid var(--primary);
		color: #fff;
	}
	&:focus {
		background-color: var(--primary);
		border: 3px solid var(--primary);
		color: #fff;
	}
}

.base2Btn {
	border: 3px solid var(--secondary);
	background-color: var(--secondary);
	color: #fff;

	&:hover {
		background-color: var(--secondary);
		border: 3px solid var(--secondary);
		color: #fff;
	}
	&:visited {
		background-color: var(--secondary);
		border: 3px solid var(--secondary);
		color: #fff;
	}
	&:focus {
		background-color: var(--secondary);
		border: 3px solid var(--secondary);
		color: #fff;
	}
}

.base3Btn {
	border: 3px solid #fff16f;
	background-color: #fff16f;
	color: #463649;
	text-transform: capitalize;

	&:hover {
		background-color: #fff16f;
		border: 3px solid #fff16f;
		color: #463649;
	}
	&:visited {
		background-color: #fff16f;
		border: 3px solid #fff16f;
		color: #463649;
	}
	&:focus {
		background-color: #fff16f;
		border: 3px solid #fff16f;
		color: #463649;
	}
}

.base4Btn {
	border: 3px solid #a3d080;
	background-color: #a3d080;
	color: #fff;
	text-transform: capitalize;

	&:hover {
		background-color: #a3d080;
		border: 3px solid #a3d080;
		color: #fff;
	}
	&:visited {
		background-color: #a3d080;
		border: 3px solid #a3d080;
		color: #fff;
	}
	&:focus {
		background-color: #a3d080;
		border: 3px solid #a3d080;
		color: #fff;
	}
}

.base5Btn {
	border: 3px solid #d08080;
	background-color: #d08080;
	color: #fff;
	text-transform: capitalize;

	&:hover {
		background-color: #d08080;
		border: 3px solid #d08080;
		color: #fff;
	}
	&:visited {
		background-color: #d08080;
		border: 3px solid #d08080;
		color: #fff;
	}
	&:focus {
		background-color: #d08080;
		border: 3px solid #d08080;
		color: #fff;
	}
}

.base6Btn {
	border: 3px solid #9745da;
	background-color: #9745da;
	color: #fff;
	text-transform: capitalize;

	&:hover {
		background-color: #9745da;
		border: 3px solid #9745da;
		color: #fff;
	}
	&:visited {
		background-color: #9745da;
		border: 3px solid #9745da;
		color: #fff;
	}
	&:focus {
		background-color: #9745da;
		border: 3px solid #9745da;
		color: #fff;
	}
}

.base7Btn {
	border: 3px solid #fff;
	background-color: #fff;
	color: #463649;
	text-transform: capitalize;

	&:hover {
		background-color: #fff;
		border: 3px solid #fff;
		color: #463649;
	}
	&:visited {
		background-color: #fff;
		border: 3px solid #fff;
		color: #463649;
	}
	&:focus {
		background-color: #fff;
		border: 3px solid #fff;
		color: #000;
	}
}

.base8Btn {
	border: 3px solid #FFCF6F;
	background-color: #FFCF6F;
	color: #fff ;
	text-transform: capitalize;

	&:hover {
		background-color: #FFCF6F;
		border: 3px solid #FFCF6F;
		color: #fff;
	}
	&:visited {
		background-color: #FFCF6F;
		border: 3px solid #FFCF6F;
		color: #fff;
	}
	&:focus {
		background-color: #FFCF6F;
		border: 3px solid #FFCF6F;
		color: #fff;
	}
}

.transparent {
	border: 3px solid #463649;
	background-color: #fff;
	color: #463649;
	text-transform: capitalize;

	&:hover {
		background-color: #fff;
		border: 3px solid #463649;
		color: #463649;
	}
	&:visited {
		background-color: #fff;
		border: 3px solid #463649;
		color: #463649;
	}
	&:focus {
		background-color: #fff;
		border: 3px solid #463649;
		color: #000;
	}
}

.themeBtn {
	border: 3px solid var(--btn-background);
	background-color: var(--btn-background);

	color: var(--btn-color);
	text-transform: capitalize;

	&:hover {
		background-color: var(--btn-background);
		border: 3px solid var(--btn-background);
		color: var(--btn-color);
	}
	&:visited {
		background-color: var(--btn-background);
		border: 3px solid var(--btn-background);
		color: var(--btn-color);
	}
	&:focus {
		background-color: var(--btn-background);
		border: 3px solid var(--btn-background);
		color: var(--btn-color);
	}
}

.primaryBtn {
	color: #fff;
	border: 3px solid var(--button-border-color);
	background-color: var(--button-background-color);

	&:hover {
		background-color: #000;
		border: 3px solid #000;
		color: #fff;
	}
	&:visited {
		background-color: #000;
		border: 3px solid #000;
		color: #fff;
	}

	&:focus {
		background-color: #000;
		border: 3px solid #000;
		color: #fff;
	}
}

.secondaryBtn {
	color: var(--button-background-color);
	border: 3px solid var(--button-border-color);
	background-color: #fff;

	&:hover {
		background-color: var(--button-border-color);
		border: 3px solid var(--button-border-color);
		color: #fff;
	}
	&:visited {
		background-color: var(--button-border-color);
		border: 3px solid var(--button-border-color);
		color: #fff;
	}
	&:focus {
		background-color: var(--button-border-color);
		border: 3px solid var(--button-border-color);
		color: #fff;
	}
}

.disabledBtn {
	cursor: not-allowed;
	color: #ededed;
	border: 3px solid #ebebe4;
	background-color: #ebebe4;

	&:hover {
		background-color: #ebebe4;
		border: 3px solid #ebebe4;
		color: #ededed;
	}
	&:visited {
		background-color: #ebebe4;
		border: 3px solid #ebebe4;
		color: #ededed;
	}
	&:focus {
		background-color: #ebebe4;
		border: 3px solid #ebebe4;
		color: #ededed;
	}
}
.blackBtn {
    background-color: black !important;
    color: white !important;
    border: 3px solid black !important;
}

@media (max-width: 450px) {
	.buttonContainer {
		width: 100% !important;
		line-height: 23px;
		letter-spacing: 0em;
		text-align: center;

		height: 2.6875rem;
		padding: 0.625rem 1.75rem;
		font-size: 0.9375rem;
	}
}
