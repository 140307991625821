.updateContainer {
  padding: 15px;
  width: 100%;
  height: max-content;
  background: #ffffff;
  border-radius: 8.44293px;

  .firstRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .name {
      font-family: 'Poppins Medium';
      font-size: 16px;
      line-height: 130%;
      color: #262626;
    }

    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 33px;
      width: 139px;
      height: 36px;
      background: #f9efff;
      border-radius: 16px;
      font-family: 'Poppins Bold';
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.065em;
      text-transform: uppercase;
      color: #463649;
    }
  }

  .secondRow {
    color: #717782;
    font-family: 'Poppins Regular';
    font-size: 0.875rem;
    line-height: 1.3125rem;

    .date {
      margin-top: 10px;
      font-family: 'Poppins SemiBold';
      font-size: 10px;
      line-height: 17px;
      color: #717782;
    }
  }

  .carousel {
    width: 100%;
    border-radius: 10px;

    .ant-carousel {
      width: 100%;
      height: auto;

      .image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background-color: #9745da;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #9745da;
    }

    svg {
      font-size: 20px;
    }
  }

  .left {
    left: 10px;
  }

  .right {
    right: 10px;
  }
}

@media (max-width: 450px) {
  .updateContainer {
    width: 100% !important;

    .firstRow .tag {
      width: max-content;
      padding: 0 10px;
    }
  }
}
