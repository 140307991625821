.customizeUrlModalContainer {
	.title {
		color: #463649;
		font-family: "Poppins SemiBold";
		font-size: 1rem;
		line-height: 2.1875rem;
		letter-spacing: 0.1875rem;

		img {
			cursor: pointer;
		}
	}

	.link {
		color: #494C52;
		font-family: "Poppins Regular";
		font-size: 0.875rem;
		line-height: 150%;
		margin-bottom: 10px;
		overflow-wrap: break-word;
		white-space: normal;
	}
}