.themesContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .theme{
        width: 252px;
        height: 278px;
        border-radius: 1.25rem;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
        padding: 20px 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        .name{
            color: #463649;
            text-align: center;
            font-family: "Poppins SemiBold";
            font-size: 1rem;
            line-height: 130%;
        }

        .overview{
            width: 14.75rem;
            height: 8.4375rem;
            border-radius: 1.25rem;
            border: 1px solid #463649;
            background: lightgray 50% / cover no-repeat, #CC8DFF;
            overflow: hidden;
            position: relative;
        }

        .colorsList{
            display: flex;
            justify-content: space-between;
            gap: 25px;
            .color{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 5px;

                .sample{
                    width: 1.75rem;
                    height: 1.75rem;
                    stroke-width: 1px;
                    border: 1px solid #463649;
                    stroke: #463649;
                    border-radius: 50%;
                    cursor: pointer;
                }

                .name{
                    color: #463649;
                    text-align: center;
                    font-family: "Poppins Bold";
                    font-size: 0.75rem;
                    line-height: 0.9375rem;
                    letter-spacing: 0.04875rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    @media(max-width: 450px){
        justify-content: center;
    }
}