.editUrlFormContainer {
    .text-and-edit-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        .url {
            color: #463649;
            font-family: 'Poppins Regular';
            font-size: 0.875rem;
            margin-bottom: 20px;
        }
    }
}
