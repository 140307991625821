.addGamesContainer{
    .allGamesSection{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        .gameBox{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 120px;
            height: 110px;
            border: 1px solid #463649;
            border-radius: 10px;
            font-family: "Poppins Bold";
            font-size: 20px;
            line-height: 25px;
            cursor: pointer;
        }

        .suggestGameButton{
            flex-basis: 100%;
            width: 100%;
            order: 1;
        }
    }

    .backButton{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 80px;
        margin-bottom: 20px;
        background: #463649;
        border-radius: 20px;
        font-family: "Poppins Regular";
        font-size: 13px;
        color: #ffffff;
        cursor: pointer;
    }
}