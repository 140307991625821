.addNotesModalContainer{
    .ant-form-item-explain-error{
        margin-bottom: 10px;
    }

    .blog-link{
        color: var(--text-grey);
        font-family: "Poppins Regular" ;
        font-size: 0.875rem;
        line-height: 1.3125rem;
        margin-bottom: 20px !important;
        margin-top: -10px !important;
        text-decoration: underline;
        display: block;

        @media (max-width: 450px){
            text-align: justify;
        }
    }
}