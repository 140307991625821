.gamesSectionContainer{
    .gameBox{
        width: 100%;
        border-radius: 30px;
        padding: 38px 20px;
        background-color: #EAD4FF;
    }


    @media (max-width: 450px) {
        .gameBox{
            padding: 38px 15.09px 38px;
            background-color: #EAD4FF;
        }
    }
}