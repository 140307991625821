.newDashboardContainer{
    padding: 1.84rem 7.75rem;

    .spinnerBox{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
        .image{
            width: 120px;
            height: 120px;
        }
    }

    .topBar{
        background-color: #FCE4F9;
        padding: 2rem;
        border-radius: 0.625rem;

        .firstRow{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .heading{
                color: #463649;
                font-family: "Poppins SemiBold";
                font-size: 1.75rem;
                line-height: 1.9375rem;
                letter-spacing: -0.045rem;
                margin-bottom: 0.5rem;
            }
        }

        .subheading{
            color: #494C52;
            font-family: "Poppins Regular";
            font-size: 1rem;
        }
    }

    .buttonBox{
        display: flex;
        gap: 10px;
        margin-top: 25px;

        .buttonContainer{
            font-family: "Poppins Regular" !important;
        }

        .transparent{
            border: 1px solid #463649 !important;
        }
    }

    .analyticsBox{
        margin-top: 25px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .newDashboardBox{
        margin-top: 1.84rem;
        display: flex;
        gap: 3%;
        .firstColumn{
            width: 49%;
            .r1FirstColumn{
                width: 100%;
                height: 16rem;
                border-radius: 0.625rem;
                background: #FCE4F9;
                color: #FCE4F9;
                box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);

                overflow: hidden;

                position: relative;
                .previewBtn{
                    padding: 8px 18px;
                    color: #FFF;
                    font-family: "Poppins Regular";
                    font-size: .875rem;
                    line-height: 0.9375rem;
                    letter-spacing: 0.04875rem;
                    border-radius: 1.25rem;
                    border: 1px solid #FFF;
                    background: #463649;
                    box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    cursor: pointer;
                }
            }

            // .r2FirstColumn{
            //     display: flex;
            //     justify-content: space-between;
            //     align-items: center;
            //     margin-top: 1.5rem;

            //     .previewBabyPage{
            //         color: #a07c81;
            //         font-family: "Poppins Regular";
            //         font-size: 0.875rem;
            //         line-height: 140%;
            //         text-decoration-line: underline;
            //         cursor: pointer;
            //     }
            // }

            
            .r3FirstColumn{
                .firstRow{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: .69rem;

                    .url{
                        color: #463649;
                        font-family: "Poppins Regular";
                        font-size: 0.875rem;
                        line-height: 150%;
                        width: 80%;
                    }
                }

                .secondRow{
                    margin-top: 0;
                    margin-bottom: 1.69rem;
                    text-align: center;
                    .passcode{
                        color: #FF0000;
                        font-family: "Poppins Regular";
                        font-size: 0.875rem;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }

            .r4FirstColumn{
                padding: 1.5rem 1rem 1rem 1rem;
                border-radius: 0.625rem;
                background: #FFF;
                box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
                margin-bottom: 2rem;
                .heading{
                    color: #463649;
                    font-family: "Poppins SemiBold";
                    font-size: 1.5rem;
                    line-height: 1.9375rem;
                    letter-spacing: -0.045rem;
                    margin-bottom: 0.5rem;
                }

                .subheading{
                    color: #494C52;
                    font-family: "Poppins Regular";
                    font-size: 0.875rem;
                    line-height: 150%;
                    margin-bottom: 1rem;
                }

                .analyticsBox{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 10px;
                    .box{
                        width: 49% !important;
                        border: 1px solid gray;
                        border-radius: .325rem;
                        padding: 0.5% 2%;
                        .heading{
                            color: #494C52;
                            font-family: "Poppins Regular";
                            font-size: 0.875rem;
                            font-family: "Poppins Bold";
                            margin: 0;
                        }

                        .subheading{
                            color: var(--cream);
                            font-family: "Poppins Regular";
                            font-size: 0.875rem;
                            font-family: "Poppins Bold";
                            margin: 0;
                        }
                    }
                }
                
            }

            .r5FirstColumn{
                width: 100%;
                border-radius: 0.625rem;

                .ourOfferedFunds{
                    .label{
                        color: #463649;
                        font-family: "Poppins SemiBold";
                        font-size: 1rem;
                        line-height: 1.25rem;
                        letter-spacing: 0.1875rem;
                    }
            
                    .offerBox{
                        margin-top: 0.5rem;
                        padding: 0.9375rem 1rem;
                        min-height: 160px;
                        max-height: max-content;
                        border-radius: 0.625rem;
                        background: #FFF;
                        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
                        border: 1px solid #D6DAFF;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left{
                            flex-grow: 1;
                            .heading{
                                color: #463649;
                                font-family: "Poppins SemiBold";
                                font-size: 1rem;
                                line-height: 1.3rem;
                                margin-bottom: .5rem;
                            }
                
                            .subheading{
                                margin-bottom: 1rem;
                                color: #494C52;
                                font-family: "Poppins Regular";
                                font-size: 0.875rem;
                                line-height: 1.3125rem;
                            }
                        }
            
                        .right{
                            width: 9.4375rem;
                            height: 7.3125rem;
                            .brandImage{
                                border-radius: 0.625rem;
                                object-fit: cover;
                            }
                
                        }
            
                        
                    }
                }
            }
        }

        .secondColumn{
            width: 49%;
            .r1SecondColumn{
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                width: 100%;
                border-radius: 0.625rem;
                background: #FFF;
                box-shadow: 0px 0px 25px 0px rgba(140, 0, 255, 0.15);
                padding: 1.5rem 1rem 1rem 1rem;

                .heading{
                    color: #463649;
                    font-family: "Poppins SemiBold";
                    font-size: 1.5rem;
                    line-height: 1.9375rem;
                    letter-spacing: -0.045rem;
                }

                .subheading{
                    color: #494C52;
                    font-family: "Poppins Regular";
                    font-size: 0.875rem;
                    line-height: 150%;
                }

                .topArea{
                    .image{
                        width: 27rem;
                        height: 12.75rem;

                        @media(max-width: 450px){
                            width: 20.0625rem;
                            height: 12.75rem;
                        }
                    }
                    .details{
                        display: flex;
                        justify-content: space-around;
                        margin: 1rem 0;
                        div{
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .headingTopArea{
                                color: #463649;
                                text-align: center;
                                font-family: "Poppins Bold";
                                font-size: 0.75rem;
                                line-height: 0.9375rem;
                                letter-spacing: 0.04875rem;
                                // text-transform: uppercase;
                                margin-bottom: 0.5rem;
                            }

                            .subheadingTopArea{
                                color: #494C52;
                                text-align: center;
                                font-family: "Poppins Regular";
                                font-size: 0.625rem;
                                line-height: 1.0625rem;
                            }
                        }
                    }
                }

                .bottomArea{
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    div{
                        display: flex;
                        justify-content: space-between;
                        color: #494C52;
                        font-family: "Poppins Regular";
                        font-size: 0.875rem;
                        line-height: 150%;

                        .total{
                            font-family: "Poppins Bold";
                        }
                    }

                    .link{
                        @media(max-width: 450px){
                            margin-top: 20px;
                        }
                    }
                }
            }

        }

        .thirdColumn{
            width: 16%;
            height: 39rem;
            border-radius: 0.625rem;
            background: #D6DAFF;
            box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
        }
    }

    .bottomDashboardBox{
        display: flex;
        justify-content: center;
        margin-top: 11.75rem;
        padding-bottom: 3.25rem;
    }

    @media(max-width: 450px){
        background: var(--theme-color);
        padding: 0rem .56rem 1.84rem .56rem;

        .topBar{
            padding-bottom: 2rem;

            .bottomShareBox{
                margin-top: 1rem;
                display: flex;
                justify-content: flex-start;
            }
        }

        .buttonBox{
            flex-wrap: wrap;
    
            .buttonContainer{
                width: max-content !important;
            }
        }
        
        .newDashboardBoxForNavigation{
            background-color: #FFF;
            padding-bottom: .2rem;
        }
        
        .newDashboardBox{
            background-color: #FFF;
            padding: 0 .66rem;
            flex-direction: column;
            margin-top: 0 !important;
            .firstColumn{
                width: 100%;

                // .r2FirstColumn{
                //     flex-direction: column;
                //     gap: 1rem;

                //     .r2FirstBtn{
                //         width: 17rem !important;
                //     }
                // }

                .r3FirstColumn{
                    flex-direction: column;
                    gap: 1.19rem
                }

                .r5FirstColumn{
                    margin-bottom: 1.5rem;
                }
            }

            .secondColumn{
                width: 100%;
            }

            .thirdColumn{
                width: 100%;
                margin-top: 1.5rem;
            }
        }

        .bottomDashboardBox{
            margin-top: 2rem;
            padding-bottom: 0rem;
            width: 94%;
            margin-left: 3%;
        }
    }
}