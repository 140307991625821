.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;

    .ant-spin-dot-item{
        background-color: var(--white);
    }
}

.spinnerForFullPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-top: 1%;
    width: 100%;
    .ant-spin-dot-item{
        background-color: var(--primary);
    }
}

.spinnerForSection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 10vh;
    .ant-spin-dot-item{
        background-color: #463649;
    }
}