.paymentContainer{
    width: 100%;

    .contributionAmountTitle{
        font-family: 'Poppins Medium';
        font-size: 1.375rem;
        color: #9E9E9E;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        text-align: left;
    }

    .contributionAmount{
        font-family: 'Recoleta Bold';
        font-weight: 700;
        font-size: 2.375rem;
        color: #262626;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        text-align: left;
        
        .small{
            font-family: 'Poppins Regular';
            font-size: 1.125rem;
            color: #9E9E9E;
        }
    }

    @media (max-width: 450px) {
        .contributionAmountTitle{
            font-size: 0.8125rem;
        }

        .contributionAmount{
            font-size: 1.5rem;
    
            .small{
                font-size: 0.875rem;
            }
        }
    }
}