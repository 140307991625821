.eventContainer {
    padding: 0.625rem 0.5rem 1rem 0.5rem;
    width: 286px;
    height: max-content;
    box-shadow: 0px 0px 25px rgba(217, 171, 254, 0.15);
    border-radius: 20px;
    background-color: #FFFFFF;
    cursor: pointer;

    .imageBox {
        position: relative;
        width: 100%;
        height: 9.6875rem;
        border-radius: 0.625rem;
        overflow: hidden;

        .image {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }

        .tag {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 33px;
            width: 99px;
            height: 36px;
            background: #F9EFFF;
            border: 1px solid black;
            border-radius: 16px;
            font-family: 'Poppins Bold';
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.065em;
            text-transform: uppercase;
            color: #463649;
            position: absolute;
            z-index: 1;
            right: 10px;
            top: 10px;
        }

        @media (max-width: 450px) {
            .tag{
                width: max-content;
                padding: 0 10px;
            }
        }
    }


    .name {
        font-family: 'Poppins SemiBold';
        font-size: 16px;
        line-height: 130%;
        color: #262626;
        margin-top: 16px;
    }

    .details {
        font-family: 'Poppins Regular';
        font-size: 10px;
        line-height: 17px;
        color: #717782;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .locationBox {
        font-family: 'Poppins Regular';
        font-weight: 400;
        font-size: 10px;
        line-height: 17px;
        color: #463649;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .locDetails {
            display: flex;
            justify-content: flex-start;
            // align-items: center;
            gap: 2px;

            .icon {
                // padding-bottom: 2px;
                padding-right: 8px;
            }

            p {
                width: 90%;
                word-break: break-word;
            }
        }

    }

    .engagementBox {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .action {
            margin: 0;
            padding: 0;
            width: 100%;
            text-align: center;
            font-family: 'Poppins Bold';
            font-size: 15px;
            line-height: 22px;
            text-transform: uppercase;
            color: #000000;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;

            .btn {
                padding: 0;
                margin: 0;
                width: 0.875rem;
                height: 0.8125rem;
                margin-bottom: 2px;
                cursor: pointer;
            }
        }
    }
}