.profileDropdownContainer{
    position: relative;
    .profileImage{
        cursor: pointer;
        width: 2.625rem;
        height: 2.625rem;
    }

    .dropdown{
        width: 149px;
        position: absolute;
        top: 130%;
        right: -10%;
        z-index: 100;
        display: none;
        visibility: hidden;
        padding: 1rem;
        flex-direction: column;
        justify-content: flex-end;
        align-items: left;
        text-align: right;
        gap: 1rem;
        border-radius: 1.25rem;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);

        p{
            color: #463649;
            font-family: "Poppins Regular";
            font-size: 1.0625rem !important;
            line-height: 109%;
            cursor: pointer;

            &:hover{
                color: #CC8DFF
            }
        }
    }

    .isDropDownVisible{
        display: inline-flex;
        visibility: visible;
    }

    @media(max-width: 450px){
        .dropdown{
            width: 149px;
            right: 0%;
            p{
            text-align: right;
            width: 100%;
            }
        }
    }
}