.confirmationModalContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .heading{
        color: #463649;
        text-align: center;
        font-family: "Poppins SemiBold";
        font-size: 1rem;
        line-height: 130%;
    }

    .subheading{
        color: #494C52;
        text-align: center;
        font-family: "Poppins Regular";
        font-size: 0.875rem;
        line-height: 150%;
        margin-top: -0.5rem;
    }
}

.confirmationModal{
    justify-content: center;

    .ant-modal-content{
        width: 19.5625rem;
        padding: 0rem;
        border-radius: 0.625rem;
        background: #FFF;
    }
}