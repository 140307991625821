.grgContainer{
	padding: 30px;
	width: 100%;
	height: max-content;
	background: #FFFFFF;
	border-radius: 15px;

	.alertBoxContainer{
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.voteCloseAlert{
			font-family: 'Poppins Medium';
			font-size: 1.375rem;
			border-radius: 5px;
			background: rgba(253, 133, 133, 0.10);
			padding: 5px 10px;
			width: max-content;
			border: none;

			.ant-alert-message{
				color: #FD8585
			}
		}
	}

	.GenderAnnouncedBanner{
		background: #F8F9FA;
		border: 3px solid #F8F9FA;
		padding: 0rem 0.9375rem;
		height: 70px;
		border-radius: 0.625rem;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100% !important;

		.text{
			font-family: 'Poppins Medium';
			font-size: 1.5rem;
		}
	}
	
	.top{
		.title{
			display: flex;
			flex-direction: row;
			align-items: center;

			font-family: 'Recoleta Bold';
			font-size: 3.125rem;
			color: #262626;
			padding: 0;
			margin: 0;

			.editGameLink{
				font-size: 1.125rem;
				font-family: "Recoleta Bold";
				cursor: pointer;
				text-align: center;
				text-decoration: underline;
				color: #CC8DFF
			}
		}

		.subtitle{
			font-family: 'Poppins Medium';
			font-size: 1.375rem;
			color: var(--text-color-1);
			padding: 0;
			margin: 0;
		}
	}

	.middle{
		margin-top: 30px;

		.buttons {
			display: flex;
			justify-content: center;
			position: relative;
			display: flex;
	  			
			.vote-button {
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: #FFF;
				border-radius: 10px;
				padding: 20px;
				width: 145px;
				max-width: 165px;
				min-height: 124.59px;
				transition: all 0.3s;
				flex: 1;
				margin-right: 30px;
			
				img {
					width: 30.17px;
					height: 36.59px;
					margin-bottom: 5px;
				}
			
				span {
					font-family: 'Recoleta Bold';
					font-style: normal;
					font-weight: 700;
					font-size: 1.375rem;
				}
			}

			.boy{
				border: 2.5px solid rgba(82, 99, 255, 0.15);
			}

			.girl{
				border: 2.5px solid rgba(204, 141, 255, 0.15);
			}

			.voteBtnDisabled {
				cursor: no-drop;
				&:hover {
					box-shadow: none;
				}
			}
		}

		.winnerCard{
			border-radius: 34px;
			position: relative;
			width: 280px;
			height: 192px;
			text-align: center;
			margin-bottom: 2%;
			padding: 50px 40px;
			background-image: url("../../../../../../../public/assets/stars.gif");
			background-size: cover;
			overflow: hidden;
	
			.title{
				font-family: 'Poppins', sans-serif;
				font-weight: 700;
				font-size: 13px;
				letter-spacing: 0.08em;
				text-transform: uppercase;
			}
	
			.subtitle{
				font-family: 'Recoleta Bold';
				font-size: 30px;
				color: #463649;
			}
		}

		.right{
			flex: 1;
		}
		
	}

	.bottom{
		margin-top: 15px;
		.dividerBox{
			height: 3px;
			background-color: #F8F9FA;
			flex: 1;
		}

		.title{
			font-family: 'Poppins Medium';
			font-size: 1.375rem;
			color: var(--text-color-1);
		}

		.subtitle{
			font-family: 'Recoleta Bold';
			font-size: 1.5rem;
			text-transform: capitalize;
			color: #000000;
		}
	}

	.votersSection{
		margin-top: 20px;
		.playersCount{
			color: #7C88A0;
			font-family: "Poppins Medium";
			font-size: 1.375rem;
		}

		.playersLink{
			color: #262626;
			font-family: "Poppins Medium";
			font-size: 1.375rem;
			text-decoration-line: underline;
			cursor: pointer;
		}
	}

	.countForMb{
		color: #7C88A0;
		font-family: "Poppins Regular";
		font-size: 0.875rem;
		visibility: hidden;
		display: none;
	}

	.voters{
		margin-top: 15px;
		max-height: 139px;
		overflow: scroll;
		visibility: hidden;
		display: none;

		.voter{
			margin-bottom: 10px;

			.name{
				display: flex;
				color: #262626;
				font-size: 1.125rem;
	
				.circle{
					width: 1.875rem;
					height: 1.875rem;
					border-radius: 24px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 10px;
	
					img{
						width: 0.9375rem;
						height: 1.137rem;
					}
				}
				
			}
	
			.count{
				color: #7C88A0;
				font-family: "Poppins Regular";
				font-size: 0.875rem;
			}
		}
	}

	.isVotersVisible {
		visibility: visible;
		display: block;
	}
	

	@media (max-width: 450px) {
		padding: 40px 15px 20px;
		height: max-content;
		border-radius: 10px;
		text-align: center;
		margin-top: 55px;

		.alertBoxContainer{
			justify-content: center;
			align-items: center;

			.voteCloseAlert{
				font-size: 0.8125rem;
			}
		}

		.GenderAnnouncedBanner{
			height: max-content;
			border-radius: 0.3125rem;
			padding: 0.625rem;
			justify-content: center;
			align-items: center;

			.text{
				font-size: 0.813rem;
			}
		}

		.editGameLink{
			font-size: 0.8125rem;
			font-family: "Recoleta Bold";
			cursor: pointer;
			text-align: center;
			text-decoration: underline;
			color: #CC8DFF;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.mobileImageBox{
			margin-top: -32%;

			.mobileImage{
				width: 120px;
				height: 120px;
			}
		}
		

		.top{
			.title{
				font-size: 1.75rem;
				justify-content: center;
			}

			.subtitle{
				font-size: 0.8125rem;
				margin-top: 10px;
			}
		}

		.middle{
			margin-top: 25px;
			justify-content: center !important;
			.buttons {
				.vote-button {
					width: 110px;
					max-width: 110px;
					min-height: 102.59px;
					padding: 15px;
					height: 102.59px;
					margin-right: 15px;
					margin-left: 15px;

					img {
						width: 26.17px;
						height: 32.59px;
						margin-bottom: 10px;
					}
				
					span {
						font-size: .85rem;
					}
				}
			}
	
			.winnerCard{
				width: 280px;
				height: 132px;
				padding: 20px 40px;
				margin-top: 10px;
				margin-bottom: 10px;

				.title{
					font-family: 'Poppins', sans-serif;
					font-weight: 700;
					font-size: 13px;
					letter-spacing: 0.08em;
					text-transform: uppercase;
				}
		
				.subtitle{
					font-family: 'Recoleta Bold';
					font-size: 24px;
					color: #463649;
				}
			}

			.right{
				display: block !important;
				width: 100% !important;
				margin-top: 25px;
				flex: 0 0 auto;
			}
			
			
		}
	
		.bottom{
			.subtitle{
				font-size: 1.125rem;
			}
		}

		.votersSection{
			margin-top: 15px;
			text-align: center;
			.playersCount{
				display: none;
				visibility: hidden;
			}
	
			.playersLink{
				width: 100%;
				text-align: center;
				font-size: 0.8125rem;
			}
		}

		.countForMb{
			font-size: 0.75rem;
			visibility: visible;
			display: block;
		}

		.voters{
			margin-top: 20px;
			max-height: 132px;
	
			.voter{
				margin-bottom: 10px;
	
				.name{
					font-size: 0.75rem;
		
					.circle{
						width: 1.25rem;
						height: 1.25rem;
						border-radius: 16px;
						margin-right: 10px;
		
						img{
							width: 0.625rem;
							height: 0.758rem;
						}
					}
					
				}
		
				.count{
					font-size: 0.75rem;
				}
			}
		}
	}
}

.grgContainer > div > div.ant-row.ant-row-end.ant-row-middle.bottom > div:nth-child(1) > div{
	border-top: 0 !important;
	color: rgba(124, 136, 160, 0.1);
	margin-bottom: 10px;
	margin-top: 15px;
}


.grgContainer > div > div.ant-row.ant-row-end.ant-row-middle.bottom > div:nth-child(1) > div > span{
	font-family: 'Poppins Medium';
	font-size: 0.8125rem;
	color: var(--text-color-1);
}

