.viewEventDetailModalContainer{
    display: flex;
    justify-content: center;
        
    .topSide{
        display: flex;
        justify-content: center !important;
        flex-direction: column;
        align-items: center !important;
        margin-top: -10% !important; 

        .imageBox{
            position: relative;
            width: 80%;
            display: flex;
            justify-content: center;

            .image{
                width: 653px;
                height: auto;
            }

            .eventInfoBox {
                position: absolute;
                z-index: 1;
                width: 38%;
                height: 68px;
                left: 31%;
                bottom: 8%;
                border-radius: 0.625rem;
                border: 1px solid rgba(255, 255, 255, 0.01);
                background: rgba(255, 255, 255, 0.90);
                backdrop-filter: blur(1.7999999523162842px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 20px;
                padding-right: 20px;
        
                .title {
                    font-family: 'Poppins Bold';
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.065em;
                    color: #463649;
                    margin-bottom: 4px;
                    text-transform: uppercase;
                }

                .pragraph {
                    font-family: 'Poppins Regular';
                    font-size: 10px;
                    line-height: 17px;
                    color: #717782;
                }
            }
        }

        .bottomSide{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .engagementBox{
                margin-top: 2%;
                margin-bottom: 2%;
                width: 30%;
            }
            .commentBtn{
                width: 30%;
            }
        }
        
    }

    .leftSide{
        position: relative;

        .headline{
            font-family: 'Poppins Bold';
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.03em;
            color: #463649;
            margin-bottom: 16px;

            .backBtn{
                margin-bottom: 3px;
                padding-right: 24px;
                cursor: pointer;
            }
        }
    
        .eventInfoBox {
            position: absolute;
            z-index: 1;
            width: 86%;
            height: 68px;
            left: 7%;
            bottom: 5%;
            border-radius: 0.625rem;
            border: 1px solid rgba(255, 255, 255, 0.01);
            background: rgba(255, 255, 255, 0.90);
            backdrop-filter: blur(1.7999999523162842px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
    
            .title {
                font-family: 'Poppins Bold';
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.065em;
                color: #463649;
                margin-bottom: 4px;
                text-transform: uppercase;
            }
    
            .pragraph {
                font-family: 'Poppins Regular';
                font-size: 10px;
                line-height: 17px;
                color: #717782;
            }
        }
    
        .image{
            width: 387.5px;
            height: auto;
            border-radius: 10px;
        }
    }

    .submitBtn{
        width: 351px;
    }
    
    @media (max-width: 450px) {
        .topSide{
            width: 100%;
    
            .imageBox{
                width: 100%;
                .image{
                    width: 100%;
                }

                .eventInfoBox {
                    width: 80%;
                    left: 10%;
                    bottom: 5%;
                }
            }

            .bottomSide{
                padding: 0 29.5px;
                .engagementBox{
                    width: 100%;
                    padding: 21px 0px;
                }
            }
            
        }

        .leftSide{
            padding: 20px 11px 0px !important;
            .image{
                width: 100%;
            }
        }

        .rightSide{
            width: 100%;
            margin: 24px 28.5px 0;
        }
    }
}

.viewEventDetailModal{
    @media (max-width: 450px) {
        .ant-modal-content{
            padding: 0px !important;
            padding-bottom: 40px !important;
        }
    }
}