.villiePromoSectionContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .heading{
        font-family: 'Poppins Bold';
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        letter-spacing: -0.03em;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 25px;
    }

    .description{
        font-family: 'Poppins Regular';
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #717782;
        text-align: center;
        padding-bottom: 30px;
    }

    @media (max-width: 450px) {

        .heading{
            padding-bottom: 20px;

        }

        .description{
            padding-bottom: 24px;

        }
    }
}
