.contributionContainer {
    .block {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 16px 18px;
        gap: 16px;
        width: 100%;
        height: max-content;
        background: #ffffff;
        border-radius: 20px;

        .title{
            font-family: 'Poppins Medium';
            font-size: 16px;
            line-height: 130%;
            color: #463649;
        }

        .helper {
            color: #463649;
            font-family: "Poppins Regular";
            font-size: 0.875rem;
            line-height: 1.3125rem;
            width: 50%;
            text-align: center;
        }

        .contributionBtn{
            width: 50%;
        }
    }


    @media (max-width: 450px){
        .block{
            // padding: 10px 12.5 24px 12.5;
            padding-top: 25px;
            padding-bottom: 10px;
            margin-bottom: 11.5%;
            gap: 1px;

            // .image{
            //     width: 22.6875rem;
            // height: 12.625rem;
            // }

            .helper{
                width: 100%;
                margin-top: 10px;
            }
            .contributionBtn{
                width: 100%;
                margin: 16px 0px;
            }
        }
    }
}