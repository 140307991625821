.guessWhoGameFormContainer {
	.guessBadge {
		width: 167px;
		height: 15px;
		padding: 0px 33px 0px 33px;
		border-radius: 16px;
		font-family: "Poppins Bold";
		font-size: 10px;
		line-height: 15px;
		letter-spacing: 0.065em;
		text-align: center;
		color: #FFFFFF;
		background-color: #9745DA;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	// ---------------------------
    .chartSectionArea {
        padding: 10px 25px 10px 10px;
        width: 100%;
        height: 124.59px;
    
        background: #F8F9FA;
        border-radius: 0.625rem;

        .bar{
            border-radius: 24px;
            background: #5263FF;
            box-shadow: 0px 8px 16px 0px rgba(54, 16, 166, 0.20);
            height: 28px;
    
            margin-top: 1.06rem;
            margin-bottom: 1.19rem;
    
            .internalBar{
                border-radius: 1.5rem;
                background: #D9ABFE;
                height: 28px;
            }
        }

        .parentName{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                color: #463649;
                font-family: "Poppins Medium";
                font-size: 0.8125rem;
            }
        }
    
        @media(max-width: 450px) {
            padding: 10px 5px;
            height: 95px;
        }
    }

	.showAllResults{
        margin-top: 1.12rem;
        .heading{
            color: #7C88A0;
            text-align: center;
            font-family: "Poppins Medium";
            font-size: 0.8125rem;
            font-weight: 500;
            margin-bottom: .5rem;
        }

        .box{
            padding: 10px;
            width: 100%;
            background: #F8F9FA;
            border-radius: 0.625rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .individual{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;

                .q{
                    color: #7C88A0;
                    font-family: "Poppins Regular";
                    font-size: 0.75rem;
                    width: 50%;
                }

                .winner{
                    color: #000;
                    font-family: "Poppins SemiBold";
                    font-size: 1rem;
                    width: 40%;
                    text-align: right;
                }
            }
        }

    }

	.guessWhoTitle{
		margin-top: 14px;
		font-family: "Poppins SemiBold";
		font-size: 16px;
		line-height: 35px;
		letter-spacing: 3px;
	}

	
}
