.engagementBoxContainer{
    width: 100%;
    .top{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 23px;

        .action{
            margin: 0;
            padding: 0;
            width: 100%;
            text-align: center;
            font-family: 'Poppins Bold';
            font-size: 15px;
            line-height: 22px;
            text-transform: uppercase;
            color: #000000;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            
            .btn{
                padding: 0;
                margin: 0;
                width: 0.875rem;
                height: 0.8125rem;
                margin-bottom: 2px;
                cursor: pointer;
            }
        }
    }
    
    .commentBox{
        margin-top: 2%;
        margin-bottom: 2%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title{
            font-family: 'Poppins Medium';
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #262626;
        }

        .comment{
            font-family: 'Poppins Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #717782;
        }

        .date{
            font-family: 'Poppins SemiBold';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 17px;
            color: #463649;
        }
    }
}