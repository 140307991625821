.setMainImageModalContainer{
    .headline{
        font-family: 'Poppins Bold';
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.03em;
        color: #463649;
        margin-bottom: 16px;
    }

    .imagesDisplayBox{
        margin: 1.5rem 0;
        display: flex;
        flex-direction: row;
        gap: .7rem;
        flex-wrap: wrap;
        .imageBox{
            position: relative;
            cursor: pointer;
            img{
                width: 6.1875rem;
                height: 6.1875rem;
                object-fit: cover;
                border-radius: 1.25rem;
            }

            .badge{
                position: absolute;
                bottom: 7%;
                right: 4%;
                width: 90%;

                text-align: center;
                border-radius: 1.25rem;
                border: 1px solid #463649;
                background: #FFF;
                box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);

                color: #463649;
                font-family: "Poppins Bold";
                font-size: 0.75rem;
                line-height: 0.9375rem;
                letter-spacing: 0.04875rem;
                text-transform: uppercase;
            }
        }
    }
}