.guessWhoGameSectionContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -40px;
    
    .gameBox{
        border-radius: 0.625rem;
        border: 2px rgba(255, 255, 255, 0.20);
        background: #FFF;
    }


    @media (max-width: 450px) {
        margin-top: -10px;
        padding-bottom: 20px;
        .firstRow{
            img{
                width: 20.97rem !important;
            }
           
        }
        .gameBox{
            padding-bottom: 20px;
        }
    }
}