.textAreaFormItem {
	label{
		color: #463649;
		font-family: "Poppins SemiBold";
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.4rem;
		letter-spacing: 0.1875rem;
		padding: 0;
		margin: 0;
		margin-bottom: .5rem;

	}

	.textArea {
		height: 170px;
		background: rgba(250, 243, 247, 0.54);
		border: 1px solid #463649;
		border-radius: 0.625rem;
		font-family: "Poppins Regular";
		font-size: 1.125rem;
		line-height: 1.875rem;
		letter-spacing: -0.03125rem;
		color: #463649;
        padding: 15px;

		&:focus {
			box-shadow: none !important;
        	border: 1px solid var(--border-color);
		}

		&:hover {
			box-shadow: none !important;
			border: 1px solid var(--border-color);
		}

		&:active {
			box-shadow: none !important;
			border: 1px solid var(--border-color);
		}

		&::placeholder {
			color: #9E9E9E;
			font-family: 'Poppins Regular';
			font-size: 1.125rem ;
			line-height: 1.875rem;
			letter-spacing: -0.03125rem;
		}
		
		&::placeholder {
			color: #9E9E9E;
			font-family: 'Poppins Regular';
			font-size: 1.125rem ;
			line-height: 1.875rem;
			letter-spacing: -0.03125rem;
		}
	}

	.ant-form-item-explain-error{
		margin-top: 10px;
	}

	.ant-input {
		box-shadow: none !important;
		outline: none !important;

		&::placeholder {
			color: #9E9E9E;
			font-family: 'Poppins Regular';
			font-size: 1.125rem;
			line-height: 1.875rem;
			letter-spacing: -0.03125rem;
		}
	}

	@media (max-width: 450px) { 
		.ant-form-item-explain-error{
			font-size: 0.8125rem;
		}
	}
}