.section{
    width: 94.5%;
    min-height: max-content;
    max-height: max-content;
    margin: 60px 2.75%;

    background: var(--theme-color);
    border-radius: 60px;

    padding: 52px 74px;


    @media (max-width: 450px){
        width: 94%;
        margin-top: 0;
        border-radius: 30px;
        padding: 20px 10px;
        margin: 20px 0 20px 3%;
    }
}

