.bInputContainer {
	label{
		font-family: 'Poppins SemiBold';
		font-style: normal;
		font-size: 1rem;
		line-height: 1.4rem;
		letter-spacing: 0.1875rem;
		width: max-content;
		color: #463649;
	}

	.input {
		height: 60px;
		width: 100%;
		border-radius: 10px;
		background: rgba(250, 243, 247, 0.54);
		border: 1px solid #463649;
        font-family: "Poppins Regular";
		font-size: 1.125rem;
		line-height: 1.875rem;
		letter-spacing: -0.03125rem;
		color: #463649;
        padding: 15px;

		&:focus {
			box-shadow: none !important;
        	border: 1px solid #463649;
		}

		&:hover {
			box-shadow: none !important;
			border: 1px solid #463649;
		}

		&:active {
			box-shadow: none !important;
			border: 1px solid #463649;
		}

		&::placeholder {
			color: #9E9E9E;
			font-family: 'Poppins Regular';
			font-size: 1.125rem ;
			line-height: 1.875rem;
			letter-spacing: -0.03125rem;
		}

		.ant-input {
			box-shadow: none !important;
			outline: none !important;
			&::placeholder {
				color: #9E9E9E;
				font-family: 'Poppins Regular';
				font-size: 1.125rem;
				line-height: 1.875rem;
				letter-spacing: -0.03125rem;
			}
		}
	}

	.ant-input {
		box-shadow: none !important;
		outline: none !important;

		&::placeholder {
			color: #9E9E9E;
			font-family: 'Poppins Regular';
			font-size: 1.125rem;
			line-height: 1.875rem;
			letter-spacing: -0.03125rem;
		}
	}

	.bordlessInLinerInput{
		height: 50px;
		border-radius: 0px !important;
		padding: 0px !important;
		margin: 0px !important;
		border: none !important;
	}

	.ant-form-item-explain-error{
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@media (max-width: 450px) { 
		
		.ant-form-item-explain-error{
			font-size: 0.8125rem;
		}
	}
}