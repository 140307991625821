.endGrgModalContainer {
	.heading {
		font-family: "Recoleta Bold";
		font-size: 1.625rem;
		color: #262626;
		padding: 0;
		margin: 0;
	}

	.buttonSection {
		margin-top: 20px;
		margin-bottom: 20px;
		.vottingButton {
			cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 15px;
			gap: 10px;

			width: 111px;
			height: 102.59px;

			border-radius: 10px;
			background-color: #9e9e9e;

			img {
				width: 30.17px;
				height: 36.59px;
				// margin-bottom: 10px;
			}

			span {
				font-family: "Recoleta Bold";
				font-style: normal;
				font-weight: 700;
				font-size: 1.125rem;
				color: #ffffff;
			}
		}
	}

	.acknowledge {
		font-family: "Poppins Medium";
		font-size: 1.375rem;
		color: var(--text-color-1);
		margin-bottom: 10px;
	}

	.endBtn {
		width: 100%;
		height: 65px;
		font-family: "Recoleta Bold";
		font-size: 1.375rem;
		color: #ffffff;
		background-color: #9e9e9e;
		border-radius: 10px;
		margin-top: 10px;
		outline: none;
		border: none;
	}

	.errorMessage {
		margin-top: 10px;
		color: var(--error);
		font-size: 1rem;
		font-family: "Poppins Regular";
	}

	.winnerCard {
		border-radius: 34px;
		position: relative;
		width: 280px;
		height: 192px;
		text-align: center;
		margin-bottom: 2%;
		padding: 50px 40px;
		background-image: url("../../../../../../../public/assets/stars.gif");
		background-size: cover;
		overflow: hidden;

		.title {
			font-family: "Poppins", sans-serif;
			font-weight: 700;
			font-size: 13px;
			letter-spacing: 0.08em;
			text-transform: uppercase;
		}

		.subtitle {
			font-family: "Recoleta Bold";
			font-size: 30px;
			color: #463649;
		}
	}

	@media (max-width: 450px) {
		.heading {
			font-size: 1.125rem;
			margin-top: 10px;
		}

		.buttonSection {
			margin-top: 10px;
			margin-bottom: 20px;
			.vottingButton {
				span {
					font-size: 0.9rem;
				}
			}
		}

		.acknowledge {
			font-size: 0.8125rem;
			margin-bottom: 0px;
		}

		.endBtn {
			margin-top: 5px;
			height: 53px;
			font-size: 1rem;
		}

		.errorMessage {
			font-size: 0.8125rem;
		}

		.winnerCard {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
}
