.navbarEditButtonsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;
    
    .editContentBtn{
        display: flex;
        width: 10.625rem;
        height: 36px;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 1.25rem;
        border: 1px solid #463649;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);

        color: #463649;
        font-family: "Poppins Bold";
        font-size: 0.75rem;
        line-height: 0.9375rem;
        letter-spacing: 0.04875rem;
        text-transform: uppercase;

        cursor: pointer;
        img{
            width: 1rem;
            height: 1rem;
        }
    }

    .isEditContentBtnSelected{
        color: #FFF;
        border: 1px solid #FFF;
        background: #463649;
    }

    .upgradePlanBtn{
        padding: 8px 18px;
        cursor: pointer !important;
			.icon{
				margin-bottom: 2px;
				margin-right: 10px;
			}
    }

    @media (max-width: 450px){
        .upgradePlanBtn{
            visibility: hidden !important;
            display: none !important;
        }
    }
}