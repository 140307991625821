.notesSectionContainer{
    .lastRow{
        margin-top: 40px;
        display: flex;
        justify-content: center;

        .btn{
            width: 388px;
        }
    }

    @media (max-width: 450px) {
        .lastRow{
            margin-top: 24px;
        }
    }
}