.pieChart {
	text {
		text-align: center;
		font-family: 'Recoleta Bold';
		font-size: 1.02995rem;

		&:first-child {
			font-size: 0.901875rem;
			line-height: 16.61px;
		}

		&:last-child {
			font-size: 0.676875rem;
			line-height: 16.61px;
		}
	}
}