.imageCropingModalContainer{
    position: relative;
    height: 400px;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;

    .crop-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 80px;
      }

    .controls {
        position: absolute;
        bottom: 20px;
        left: 50%;
        width: 50%;
        transform: translateX(-50%);
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .label{
            font-size: .9rem;
            font-family: "Poppins Regular";
            color: #FFFFFF;
            margin-right: 10px;
        }
    }

    @media (max-width: 450px){
        margin-top: 40px;
    }
}